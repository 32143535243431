<template>
  <div class="content">
    <!-- 分类导航 -->
    <TypeNav />
    <div class="box">
      <div class="right">
        <!--路由出口-->
        <transition name="el-fade-in-linear">
          <div class="main-content">
            <div class="content">
              <router-view></router-view>
            </div>
          </div>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
import TypeNav from "@/components/TypeNav"
export default {
  name: 'OCRrecognitionIndex',
  components: { TypeNav, },
  data() {
    return {
    }
  },

  mounted() {
  },

  methods: {
  },
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, .5);
}

.main-content {
  margin-top: 74px;
  min-height: 90vh;
  background: #fff;
  overflow-x: auto;
  overflow-y: auto;

}
</style>