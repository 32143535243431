<template>
    <div class="box" style="max-width: 1200px;margin: 0 auto;">
        <!-- 成绩框 -->
        <div class="tzy-page-wrapper">
            <div style="display: inline-block;">
                <div class="f12 el-row">
                    <span>{{ basicInfo.provinceName }}</span>
                    <span>{{ basicInfo.subjectGroup !== undefined ? basicInfo.subjectGroup.split(',').join('/') : ''
                        }}</span>
                    <span>{{ basicInfo.expectedScore }}分</span>
                </div>
            </div>
            <div class="zyb" @click="gozyTable()">
                我的志愿表
            </div>
        </div>
        <div class="tabs">
            <div :class="leibiestr == item.batch ? 'tabActive tab' : 'tab'" v-for="(item, index) in batchDataUrlList"
                :key="index" @click="seleTab(item.batch)">
                <div class="t">
                    <div class="tit" :style="{ color: leibiestr == item.batch ? '#406AE6' : '' }">{{ item.batch }}
                    </div>
                    <div class="tuijian">
                        <img id="image" v-if="item.recommend" src="./images/编组@2x (1).png" alt="">
                    </div>
                </div>
                <div class="b">批次线{{ item.score }}分，{{ item.price }}</div>
            </div>
        </div>
        <!-- canvas -->
        <div class="canvas-wrapper" @click="go()">
            <img id="image" src="./images/编组 2@2x.png" alt="">
        </div>
        <!-- 按钮 -->
        <div class="f16 mt30 pd10 pl40  one-key-but" @click="showTab = !showTab">
            <span>
                个性化设置
                <i v-if="showTab" class="el-icon-arrow-up"></i>
                <i v-else class="el-icon-arrow-down"></i>
            </span>
        </div>
        <!-- 筛选框 -->
        <div class="tab" v-if="showTab">
            <el-tabs type="card">
                <!-- <el-tab-pane> -->
                <span slot="label"><i class="el-icon-office-building"></i> 院校优先</span>
                <div class="tzy-filter-wrapper">
                    <div class="filter-list">
                        <span class="filter-list-title">
                            院校省份
                        </span>
                        <div class="filter-list-tags">
                            <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in regionList"
                                :key="it.Id" @click="selectRegion(it, index)">
                                {{ it.Name }}
                            </span>
                        </div>
                    </div>
                    <div class="filter-list">
                        <span class="filter-list-title">
                            院校类型
                        </span>
                        <div class="filter-list-tags">
                            <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in typeList"
                                :key="it.Id" @click="selectType(it, index)">
                                {{ it.Name }}
                            </span>
                        </div>
                    </div>
                    <div class="flex college-level-wrapper">
                        <div class="filter-list filter-list-college">
                            <span class="filter-list-title">
                                院校层次
                            </span>
                            <div class="filter-list-tags">
                                <span :class="it.chenck == true ? 'tag active' : 'tag'"
                                    v-for="(it, index) in gradationList" :key="it.Id"
                                    @click="selectGradation(it, index)">
                                    {{ it.Name }}
                                </span>
                            </div>
                        </div>
                        <span class="sx">|</span>
                        <div class="filter-list tzy-filter-list-college">
                            <div class="filter-list-tags">
                                <span :class="it.chenck == true ? 'tag active' : 'tag'"
                                    v-for="(it, index) in gradationList2" :key="it.Id"
                                    @click="selectGradation2(it, index)">
                                    {{ it.Name }}
                                </span>
                            </div>
                        </div>
                        <span class="sx">|</span>
                    </div>
                </div>
                <div class="myFilterRecordBlockRow">
                    <div class="customer-selected-tags myFilterRecordBlock">
                        <span class="title">已选条件：</span>
                        <div class="tags" style="min-height: 40px;">
                            <el-tag v-for="(tag) in regionArr" :key="tag.Name" closable @close="regionClose(tag)">
                                <i class="el-icon-office-building"></i>
                                {{ tag.Name }}
                            </el-tag>
                            <el-tag v-for="(tag) in typeArr" :key="tag.Name" closable @close="typeClose(tag)">
                                <i class="el-icon-office-building"></i>
                                {{ tag.Name }}
                            </el-tag>
                            <el-tag v-for="(tag) in gradationArr" :key="tag.Name" closable @close="gradationClose(tag)">
                                <i class="el-icon-office-building"></i>
                                {{ tag.Name }}
                            </el-tag>
                            <el-tag v-for="(tag) in gradationArr2" :key="tag.Name" closable
                                @close="gradation2Close(tag)">
                                <i class="el-icon-office-building"></i>
                                {{ tag.Name }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="button-actions pr10" @click="rest()">重置已选条件</div>
                </div>
                <!-- </el-tab-pane> -->
                <!-- <el-tab-pane>
                    <span slot="label"><i class="el-icon-reading"></i> 专业优先</span>
                    专业优先
                </el-tab-pane> -->
            </el-tabs>
        </div>
        <!-- 说明 -->
        <div class="mt40 text-999 pb80">
            <p class="f18 text-333" style="font-weight: 600;">推荐原理及说明：</p>
            <p class="f14 mt5">
                依据考生成绩，并结合用户关注过的、填报过的、浏览过的院校、专业、职业为画像及个性化设置要求，配合专业定位测评及院校、专业热门指数，按冲、稳、保规则录取概率从小到大排序生成个性化的志愿表。苛刻的个性化设置条件可能会导致志愿表生成数量过少的情况，建议扩大设置范围。
            </p>
            <p class="f14 mt5">
                注意：一键填报生成的志愿表仅用于模拟参考，建议考生依据自身条件和需求再此基础上修改调整出符合自己的最终方案。
            </p>
        </div>
    </div>
</template>

<script>
import { request2 } from "@/utils/request";
import { GetRegionList, GetCustomerInfoById } from "@/api/home";
export default {
    name: 'VoluntaryReportingIndex',
    components: {
    },
    data() {
        return {
            basicInfo: '',
            batchDataUrl: '',
            batchDataUrlList: [],
            leibiestr: '',
            // 批次线选择
            yearArea: 0,

            // 推荐
            recommend: 0,

            // 我的志愿表数量
            total: 0,

            // 设置按钮icon显示隐藏
            showTab: false,

            // 用户个人数据
            userInfoObj: {},

            // 批次线
            yearAreaList: [],
            // 已选择的院校省份
            regionArr: [],

            // 已选择的院校类型
            typeArr: [],

            // 已选择的院校层次（1）
            gradationArr: [],

            // 已选择的院校层次（2）
            gradationArr2: [],

            // 已选条件数组
            tags: [],

            // 院校类型
            regionList: [],

            // 院校类型
            typeList: [
                { Id: -2, Code: "-2", Name: "不限", chenck: true },
                { Id: 0, Code: "0", Name: "综合", chenck: false },
                { Id: 1, Code: "1", Name: "理工类", chenck: false },
                { Id: 2, Code: "2", Name: "医学类", chenck: false },
                { Id: 3, Code: "3", Name: "军事类", chenck: false },
                { Id: 4, Code: "4", Name: "语言类", chenck: false },
                { Id: 5, Code: "5", Name: "师范类", chenck: false },
                { Id: 6, Code: "6", Name: "财经类", chenck: false },
                { Id: 7, Code: "7", Name: "政法类", chenck: false },
                { Id: 8, Code: "8", Name: "民族类", chenck: false },
                { Id: 9, Code: "9", Name: "农林类", chenck: false },
                { Id: 10, Code: "10", Name: "艺术类", chenck: false },
                { Id: 11, Code: "11", Name: "体育类", chenck: false },
                { Id: 12, Code: "12", Name: "其他", chenck: false },
            ],

            // 院校层次（1）
            gradationList: [
                { Id: -3, Code: "-1", Name: "不限", chenck: true },
                { Id: 0, Code: "0", Name: "公办", chenck: false },
                { Id: 1, Code: "1", Name: "民办", chenck: false },
                { Id: 2, Code: "2", Name: "中外合作", chenck: false },
                { Id: 3, Code: "3", Name: "港澳台", chenck: false },
            ],

            // 院校层次（2）
            gradationList2: [
                { Id: 1, Code: "1", Name: "教育部", chenck: false },
                { Id: 2, Code: "2", Name: "省政府", chenck: false },
                { Id: 3, Code: "3", Name: "其他部委", chenck: false },
                { Id: 4, Code: "4", Name: "军校", chenck: false },
            ],

            // 筛选的条件
            listObj: {
                province: [],
                subjectType: [],
                feature: [],
            },

        };
    },

    mounted() {
        this.getUserInfo()
        this.getRegionList()
    },

    methods: {
        // 获取个人信息
        async getUserInfo() {
            const res = await GetCustomerInfoById()
            this.basicInfo = res.data.result.userExtend;
            this.batchDataUrl = res.data.result.batchDataUrl;
            this.getBatchDataUrlList()
        },

        //获取批次线政策 
        getBatchDataUrlList() {
            var that = this
            request2.get(this.batchDataUrl)
                .then(res => {
                    let list = res.data.result[0].batches;
                    if (that.basicInfo.sp == 0 || that.basicInfo.sp == 3) {

                        // 使用正则表达式提取 "物" 或 "史"
                        let regex = /(物|史)/; // 正则表达式匹配 "物" 或 "史"
                        let match = that.basicInfo.subjectGroup.match(regex);
                        if (match) {
                            console.log(match[0]); // 输出匹配到的第一个字符串，如 "物"
                        } else {
                            console.log("没有找到匹配的科目");
                        }


                        if (match[0] === '物') {
                            // 筛选出 '理科' 数据
                            that.batchDataUrlList = list.filter(item => item.course === '理科');
                        } else if (match[0] === '史') {
                            // 筛选出 '文科' 数据
                            that.batchDataUrlList = list.filter(item => item.course === '文科');
                        }
                    } else {
                        that.batchDataUrlList = list;
                    }
                    let stop = false; // 停止标志

                    that.batchDataUrlList.forEach((its) => {
                        if (stop) return; // 如果已经找到符合条件的分数，停止遍历
                        if (that.basicInfo.expectedScore >= its.score) {
                            that.leibiestr = its.batch
                            its.recommend = true
                            stop = true; // 设置停止标志
                        }
                    })
                    that.batchDataUrlList.forEach((its) => {
                        if (that.basicInfo.expectedScore < its.score) {
                            its.price = `线差` + (its.score - that.basicInfo.expectedScore) + `分`
                        } else {
                            its.price = `线高` + (that.basicInfo.expectedScore - its.score) + `分`
                        }
                    })


                })
                .catch(error => {
                    console.error('Request failed:', error);  // 打印错误信息
                });
        },

        // 选择批次线
        seleTab(name) {
            this.leibiestr = name
        },



        // 去我的志愿表
        gozyTable() {
            this.$router.push('/Home/zyTable')
        },

        // 一键填报
        go() {
            var serveObj = {
                batchname: this.leibiestr,
                location: this.basicInfo.provinceCode,
                p: this.basicInfo.sp,
                score: this.basicInfo.expectedScore,
                subjects: this.basicInfo.subjectGroup.split(','),
                ...this.listObj
            }
            let str = JSON.stringify(serveObj)

            console.log('设置筛选的条件', serveObj);
            // this.listObj.BatchName = this.leibiestr
            // let str = JSON.stringify(serveObj)
            this.$router.push({ path: '/Home/zybEditor', query: { str } })
        },

        // 选择院校省份
        selectRegion(it, idx) {
            this.disport(it, idx, this.regionList, this.regionArr, 0)
        },

        // 选择院校类型
        selectType(it, idx) {
            this.disport(it, idx, this.typeList, this.typeArr, 0)
        },

        // 选择院校层次1
        selectGradation(it, idx) {
            this.disport(it, idx, this.gradationList, this.gradationArr, 1)
        },

        // 选择院校层次2
        selectGradation2(it, idx) {
            this.disport(it, idx, this.gradationList2, this.gradationArr2, 1)
        },

        // 获取省份数据
        async getRegionList() {
            const res = await GetRegionList({
                code: 0
            })
            this.regionList = res.data.response
            this.regionList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.regionList.unshift({ Id: -1, Code: "-1", Name: "不限", chenck: true })
        },

        // 合并
        mergeARR() {
            this.listObj.province = []
            this.listObj.subjectType = []
            this.listObj.feature = []
            this.regionArr.forEach((item) => {
                this.listObj.province.push(item.Name)
            })
            this.typeArr.forEach((item) => {
                this.listObj.subjectType.push(item.Name)
            })
            this.gradationArr.forEach((item) => {
                this.listObj.feature.push(item.Name)
            })
            this.gradationArr2.forEach((item) => {
                this.listObj.feature.push(item.Name)
            })
            this.tags = [...this.regionArr, ...this.gradationArr, ...this.gradationArr2, ...this.typeArr]
        },

        // 重置
        rest() {
            this.tags = []
            this.regionArr = []
            this.gradationArr = []
            this.gradationArr2 = []
            this.typeArr = []
            this.listObj.province = []
            this.listObj.subjectType = []
            this.listObj.feature = []
            this.yearArea = this.recommend
            this.regionList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.typeList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.gradationList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.gradationList2.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.regionList[0].chenck = true
            this.typeList[0].chenck = true
            this.gradationList[0].chenck = true
        },

        // 处理
        disport(it, idx, list, Arr, type) {
            if (it.Name !== '不限') {
                list[0].chenck = false
                var index = Arr.findIndex(item => item.Name == it.Name)
                // 没有就添加
                if (index == -1) {
                    Arr.push(it)
                    list[idx].chenck = true
                    this.mergeARR()
                    // 有就删除
                } else {
                    Arr.splice(index, 1)
                    list[idx].chenck = false
                    this.mergeARR()
                }
                if (type == 1) {
                    const b = this.gradationList.every(item => item.chenck == false)
                    const c = this.gradationList2.every(item => item.chenck == false)
                    if (b && c) {
                        this.gradationList[0].chenck = true
                    } else {
                        this.gradationList[0].chenck = false
                    }
                } else {
                    const b = list.some(item => item.chenck == true)
                    if (b) {
                        list[0].chenck = false
                    } else {
                        list[0].chenck = true
                    }
                }
            } else {
                if (type == 1) {
                    this.gradationArr = []
                    this.gradationArr2 = []
                    this.gradationList.forEach((item) => {
                        this.$set(item, "chenck", false)
                    })
                    this.gradationList2.forEach((item) => {
                        this.$set(item, "chenck", false)
                    })
                    this.gradationList[0].chenck = true
                    this.mergeARR()
                } else {
                    Arr.splice(0, Arr.length);//清空数组
                    list.forEach((item) => {
                        this.$set(item, "chenck", false)
                    })
                    list[0].chenck = true
                    this.mergeARR()
                }
            }
        },

        // 标签删除处理
        Close(List, Arr, Tag, Type) {
            List[List.indexOf(Tag)].chenck = false
            Arr.splice(Arr.indexOf(Tag), 1)
            this.mergeARR()
            if (Type == 1) {
                const b = this.gradationList.every(item => item.chenck == false)
                const c = this.gradationList2.every(item => item.chenck == false)
                if (b && c) {
                    this.gradationList[0].chenck = true
                } else {
                    this.gradationList[0].chenck = false
                }
            } else {
                const b = List.every(item => item.chenck == false)
                if (b) {
                    List[0].chenck = true
                }
            }
        },

        regionClose(tag) {
            this.Close(this.regionList, this.regionArr, tag, 0)
        },
        typeClose(tag) {
            this.Close(this.typeList, this.typeArr, tag, 0)
        },
        gradationClose(tag) {
            this.Close(this.gradationList, this.gradationArr, tag, 1)
        },
        gradation2Close(tag) {
            this.Close(this.gradationList2, this.gradationArr2, tag, 1)
        },
    },
};
</script>


<style lang="less" scoped>
.el-row {
    display: flex;
    align-items: center;
    background: #FFFAF5;
    border-radius: 5px;
    padding: 10px 20px;

    span {
        margin-right: 50px;
    }
}

.tzy-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 18px;
    z-index: 2;

    .zyb {
        background: #187cff;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        color: #FFFFFF;
    }

    .tzy-main-color {
        color: #2577e3;
    }

    .total,
    .total {
        color: #2577e3;
    }
}

.tabs {
    margin-top: 50px;
    width: 1200px;
    display: flex;
    justify-content: center;

    .tabActive {
        background: rgb(243, 246, 255) !important;
    }

    .tab {
        cursor: pointer;
        width: 200px;
        height: 100px;
        background: #F5F5F5;
        border-radius: 20px;
        margin-right: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .t {
            display: flex;
            align-items: center;

            .tit {
                font-weight: bold;
                color: #333333;
                font-size: 20px;
                margin-right: 5px;
            }

            .tuijian {
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .b {
            color: #333333;
            font-size: 12px;
        }
    }
}

.canvas-wrapper {
    position: relative;
    top: 25px;
    min-height: 300px;

    #image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 300px;
        cursor: pointer;
        z-index: 19;
    }
}

.texts {
    text-align: center;
    margin-top: 100px;
    font-size: 18px;
    font-family: Microsoft YaHei, SimSun, Arial, sans-serif;
}

.one-key-but {
    width: 320px;
    height: 55px;
    border-radius: 10px;
    border: 1px solid #187cff;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    color: #2577e3;
    margin-top: 30px;
    margin-bottom: 30px;
}

.text-999 {
    color: #999;
}

.pb80 {
    padding-bottom: 80px;
}

.mt40 {
    margin-top: 40px;
}

.text-333 {
    color: #333;
}

.f18 {
    font-size: 18px;
}

.f14 {
    font-size: 14px;
}

.mt5 {
    margin-top: 5px;
}

.tzy-filter-wrapper {
    padding: 24px;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;

    .filter-list {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px dashed #e6e6e6;

        .filter-list-title {
            font-size: 14px;
            text-align: center;
            color: #9b9b9b;
            margin-top: 13px;
        }

        .filter-list-tags {
            margin-left: 10px;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            .tag {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                font-style: normal;
                margin: 10px;
                padding: 2px 6px;
                margin-right: 10px;
                text-align: center;
                border-radius: 4px;
                cursor: pointer;
                box-sizing: border-box;
            }

            .tag:hover {
                color: #187cff;
            }

            .active {
                border: 1px solid #187cff;
                color: #187cff;
            }
        }

    }

    .college-level-wrapper {
        display: flex;
        border-bottom: 1px dashed #e6e6e6;

        .sx {
            font-weight: 100;
            height: 47px;
            display: flex;
            align-items: center;
            color: #e6e6e6;
            transform: translateY(-2px);
        }

    }
}

.myFilterRecordBlockRow {
    overflow: hidden;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    display: flex;
    height: auto;
    transition: all 2s ease-in-out;
    display: flex;
    align-items: center;

    .button-actions {
        font-size: 16px;
        color: #187cff;
        margin-right: 20px;
        cursor: pointer;
    }

    .customer-selected-tags {
        flex: 1;
        min-height: 50px;
        padding: 6px 14px 6px 20px;
        overflow: hidden;
        display: flex;
        align-items: center;

        .title {
            width: 90px;
            font-size: 14px;
            color: #9b9b9b;
        }

        .tags {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            ::v-deep .el-tag {
                margin-right: 10px;
                margin-top: 3px;
                font-size: 14px;
            }
        }
    }
}

::v-deep .el-tabs__item {
    background-color: #f2f2f2;
    width: 200px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    margin-right: -1px;
    border-bottom: 1px solid #e5e5e5;
}

::v-deep .el-tabs__item:hover {
    color: #187cff;
}

::v-deep .is-active {
    border-bottom: 0 solid #e5e5e5;
    background-color: #fff;
    color: #187cff;
}

::v-deep .el-tabs__header {
    margin: 0;
}

.el-checkbox {
    padding: 10px;
    margin-right: 10px;
    width: 100%;
    color: #606266;
    font-weight: 500;
    font-size: 14px;
    width: 160px;
    cursor: pointer;
    user-select: none;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
}
</style>