<template>
  <div class="box" style="max-width: 1200px; margin: 0 auto; padding-top: 10px">
    <div class="Select">
      <el-select
        style="width: 100px; padding-right: 10px !important"
        size="small"
        v-model="serveObj.Years"
        placeholder="请选择"
        @change="YearsChange($event)"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        style="width: 100px; padding-right: 10px !important"
        size="small"
        v-model="serveObj.Pronvice"
        placeholder="请选择"
        @change="YearsChange($event)"
      >
        <el-option
          v-for="item in options1"
          :key="item.ProvinceName"
          :label="item.ProvinceName"
          :value="item.ProvinceName"
        >
        </el-option>
      </el-select>
      <div class="query" style="width: 300px">
        <el-input
          size="small"
          v-model="serveObj.Score"
          placeholder="请输入内容"
          @keyup.enter.native="YearsChange()"
        ></el-input>
        <el-button size="small" type="primary" @click="YearsChange()"
          >查询</el-button
        >
      </div>
    </div>
    <!--  -->
    <!-- <el-row class="weiciqujian">
            <el-col :span="6">
                <p class="f28 lh40 mb10 text-333 fw-600">1745 ~ 1836</p>
                <p class="f14 lh20 text-999">位次区间</p>
            </el-col>
            <el-col :span="6">
                <p class="f28 lh40 mb10 text-333 fw-600">92</p>
                <p class="f14 lh20 text-999">同分人数</p>
            </el-col>
            <el-col :span="6">
                <p class="f28 lh40 mb10 text-333 fw-600">1836</p>
                <p class="f14 lh20 text-999">建议位次</p>
            </el-col>
            <el-col :span="6">
                <p class="f28 lh40 mb10 text-333 fw-600">低</p>
                <p class="f14 lh20 text-999">
                    竞争指数
                    <span>
                        <div role="tooltip" id="el-popover-4301" aria-hidden="true"
                            class="el-popover el-popper el-popover--plain" tabindex="0"
                            style="width: 260px; display: none;">
                            <div class="el-popover__title">数据说明：</div>【同分人数越多，竞争指数越高】
                        </div><i class="icon-bangzhuzhongxin iconfont f12 helpIcon el-popover__reference"
                            aria-describedby="el-popover-4301" tabindex="0"></i>
                    </span>
                </p>
            </el-col>
        </el-row> -->
    <!--  -->
    <div class="table">
      <div class="title">
        <div style="font-weight: bold" class="left">历年等位分</div>
        <div style="font-size: 14px" class="right">
          历年等位分即历年相近位次所对应的分数，可用于比较历年高考的成绩竞争情况
        </div>
      </div>
      <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="SameScoreLineList"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="Years" label="年份"> </el-table-column>
        <el-table-column prop="ScoreRegion" label="位次区间"> </el-table-column>
        <el-table-column prop="Score" label="等位分"> </el-table-column>
      </el-table>
    </div>
    <div class="table">
      <div class="title">
        <div style="font-weight: bold" class="left">
          {{ serveObj.Pronvice }} {{ serveObj.Years }} 综合 分段表
        </div>
      </div>
      <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="ScoreLineList"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="Score" label="分数"> </el-table-column>
        <el-table-column prop="ScoreRegion" label="位次区间"> </el-table-column>
        <el-table-column prop="SumCount" label="同分人数"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { GetSameScoreLine, GetScoreLine } from "@/api/diplomaMill";
import { GetSeVolunteerInitialization } from "@/api/home";

export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      SameScoreLineList: [],
      ScoreLineList: [],
      options: [
        {
          value: 2025,
          label: 2025,
        },
        {
          value: 2024,
          label: 2024,
        },
        {
          value: 2023,
          label: 2023,
        },
      ],
      options1: [],
      serveObj: {
        Years: 2024,
        Pronvice: "",
        Score: "",
      },
    };
  },

  mounted() {
    this.serveObj.Years =
      this.$store.state.user.studentInfo.userExtend.year || 2025;
    this.serveObj.Pronvice =
      this.$store.state.user.studentInfo.userExtend.provinceName || "山东省";
    this.serveObj.Score =
      this.$store.state.user.studentInfo.userExtend.expectedScore || 500;
    this.getSameScoreLineList();
    this.getScoreLinList();
    this.getSeVolunteerList();
  },

  methods: {
    // 获取省份
    async getSeVolunteerList() {
      const res = await GetSeVolunteerInitialization();
      this.options1 = res.data.response;
    },
    YearsChange() {
      if (this.serveObj.Score !== 0) {
        this.getSameScoreLineList();
        this.getScoreLinList();
      } else {
        this.$message.error("请输入成绩");
      }
    },
    // 获取历年份数据
    async getSameScoreLineList() {
      const res = await GetSameScoreLine({
        ...this.serveObj,
      });
      this.SameScoreLineList = res.data.response;
    },
    // 获取省份学年位次信息列表
    async getScoreLinList() {
      const res = await GetScoreLine({
        ...this.serveObj,
      });
      this.ScoreLineList = res.data.response;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: Microsoft YaHei, SimSun, Arial, sans-serif !important;
}

.Select {
  display: flex;

  .query {
    display: flex;

    .el-button--primary {
      background-color: #187cff !important;
      border-color: #187cff !important;
      color: #fff !important;
    }
  }
}

.weiciqujian {
  margin: 20px 0;
  width: 100%;
  height: 114px;
  border-radius: 4px;
  padding-top: 22px;
  padding-bottom: 22px;
  text-align: center;
  background: #f2f2f2;

  .text-333 {
    color: #333 !important;
  }

  .lh40 {
    line-height: 40px !important;
  }

  .f28 {
    font-size: 28px !important;
  }

  .mb10 {
    margin-bottom: 10px !important;
  }

  .text-999 {
    color: #999 !important;
  }

  .lh20 {
    line-height: 20px !important;
  }

  .f14 {
    font-size: 14px !important;
  }
}

.table {
  margin-top: 40px;

  ::v-deep .is-leaf {
    border-top: 1px solid #ebeef5;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-size: 16px;
    padding-bottom: 20px;
  }
}
</style>
