<template>
  <div class="box" style="max-width: 1200px; margin: 0 auto">
    <div class="my-zytable-page-title page-title-bl4 mt60 mb15">
      <span class="pull-left">{{ phone }} 的志愿表</span>
      <!-- <span class="pull-right f14 my-zytable-filter"><b class="text-primary active">个人</b>|<b>专家</b></span> -->
    </div>
    <div class="tableList">
      <el-table
        border
        :data="list"
        align="center"
        style="width: 100%"
        :header-cell-style="{
          background: '#eaeaea',
          color: '#606266',
          fontWeight: 400,
        }"
      >
        <el-table-column type="index" label="序号" align="center" width="50" />
        <el-table-column prop="tableName" align="center" width="150">
          <template slot="header">
            <div style="text-align: center">
              <div>志愿表</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="CP" align="center">
          <template slot="header">
            <div style="text-align: center">
              <div>靠谱率</div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="批次" align="center">
          <template slot-scope="scope">
            <div style="text-align: center">
              <div>{{ scope.row.batchName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="score" align="center">
          <template slot="header">
            <div style="text-align: center">
              <div>成绩</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subjectClaim" align="center">
          <template slot="header">
            <div style="text-align: center">
              <div>科目</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <div style="text-align: center">
              <div>{{ scope.row.type }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="customerName" align="center">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>状态</div>
                        </div>
                    </template>
                </el-table-column> -->
        <el-table-column prop="createTime" align="center" width="180">
          <template slot="header">
            <div style="text-align: center">
              <div>生成时间</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goTY(scope.row.vId, scope.row.personlocationCode)"
              >查看</el-button
            >
            <el-button
              type="text"
              @click="
                getExport(
                  scope.row.vId,
                  scope.row.personlocationCode,
                  scope.row.customerName
                )
              "
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getCustomerTbList } from "@/api/News";
import { Export } from "@/api/zybEditor";
import { downloadFile } from "@/utils/download"; // 导入文件下载工具
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      list: [],
      phone: "",
    };
  },

  mounted() {
    this.phone = this.$route.query.phone;
    this.getList();
  },

  methods: {
    // 获取列表
    async getList() {
      const res = await getCustomerTbList({
        Phone: this.phone,
      });
      this.list = res.data.result;
    },

    async getExport(Id, location, customerName) {
      const response = await Export({
        Id: Id,
        location: location,
      });
      // 处理下载
      downloadFile(response, customerName);
    },
    goTY(VId, Code) {
      console.log(VId);
      this.$router.push({
        path: "/Home/zybEditorTwo",
        query: { VId: VId, Code: Code, role: true },
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  float: right;
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  margin-top: 30px !important;
}

.page-title-bl4 {
  border-color: #2577e3 !important;
}

.page-title-bl4 {
  border-left: 4px solid;
  color: #333;
  font-size: 20px;
  line-height: 22px;
  height: 22px;
  padding-left: 10px;
  font-weight: 500;
  margin-bottom: 15px !important;
  margin-top: 30px !important;

  .pull-left {
    float: left !important;
  }

  .pull-right {
    float: right !important;
    font-size: 14px !important;
    cursor: pointer;

    b {
      font-weight: bold;
    }

    .text-primary {
      color: #2577e3 !important;
    }
  }
}
</style>
