import Cookies from "js-cookie";

const TokenKey = "token";
const TokenKey1 = "serviceToken";
const IdKey = "Id";
const thridtokenKey = "thridtoken";
const unsrInfo = "unsrInfo";
const unsrInfo1 = "unsrInfo1";
const unsrInfo2 = "unsrInfo2";
export function getServiceToken() {
  return Cookies.get(TokenKey1);
}
export function setServiceToken(token) {
  return Cookies.set(TokenKey1, token);
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  const domain =
    process.env.NODE_ENV === "production" ? ".ycymedu.com" : undefined;

  return Cookies.set(TokenKey, token, { domain });
}

export function setId(Id) {
  return Cookies.set(IdKey, Id);
}
export function getId() {
  return Cookies.get(IdKey);
}
export function removeId() {
  return Cookies.remove(IdKey);
}
export function removeToken() {
  const domain =
    process.env.NODE_ENV === "production" ? ".ycymedu.com" : undefined;

  // 删除指定域名的 token
  return Cookies.remove(TokenKey, { domain });
}
export function removeToken1() {
  return Cookies.remove(TokenKey);
}
export function setAccount(account) {
  return Cookies.set(unsrInfo, account, "7d");
}

export function setPassword(password) {
  return Cookies.set(unsrInfo1, password, "7d");
}

export function setAutoLogin(autoLogin) {
  return Cookies.set(unsrInfo2, autoLogin, "7d");
}

export function getAccount() {
  return Cookies.get(unsrInfo);
}

export function getPassword() {
  return Cookies.get(unsrInfo1);
}

export function setthridtoken(thridtoken) {
  return Cookies.set(thridtokenKey, thridtoken, "7d");
}

export function getthridtoken() {
  return Cookies.get(thridtokenKey);
}
