<template>
  <div class="box">
    <div style="opacity: 0; height: 20px">/</div>
    <div class="content">
      <div class="open">
        <div class="vip">
          <div class="money">¥ 298</div>
          <div class="text">
            已选：24年高考，山东省，综合类，有效期截至到{{
              new Date().getFullYear() + 1
            }}-{{ new Date().getMonth() + 1 }}-{{ new Date().getDate() }}
          </div>
          <div class="but" @click="clickSHo()">立即开通</div>
        </div>
      </div>
    </div>
    <div class="detailsPicture">
      <img src="./images/编组 3@2x11.png" alt="" />
    </div>
    <div class="need-to-know">
      <div class="title">购买须知：</div>
      <div>适用考生：新高考省份考生（不含艺术体育类）</div>
      <div>适用批次：普通类（不包含提前批）</div>
      <div>
        适用平台：使用手机号登录小程序或官方登六纬志愿系列产品，都可以享受模拟志愿VIP服务。
      </div>
      <div>使用时间：有效期一年，有效期至2024-09-01</div>
      <div>成绩修改：成为VIP后，一天支持多次修改分数。</div>
      <div>
        温馨提示：系统仅提供志愿填报参考服务，招生计划与录取数据请以考试院公布为准。正式填报时请前往考试院官网进行填报，并根据官方信息，做好志愿核对。
      </div>
    </div>
    <!-- 悬浮开通vip -->
    <div
      v-show="showButtonTransition"
      :class="showButtonTransition ? 'fade-in OpenVip' : 'OpenVip'"
    >
      <div class="op">
        <div class="lVip">已有20988名同学开通志愿填报VIP卡</div>
        <div class="rVip">
          <div class="a">¥ 298</div>
          <div class="kt" @click="clickSHo()">立即开通</div>
        </div>
      </div>
    </div>
    <!-- 会员弹框 -->
    <div class="mask" v-if="show" @click="showWs()"></div>
    <div class="vipDialog" v-if="dialogVisible">
      <div class="top">
        <div class="info">
          <div class="photo">
            <img :src="userInfo.AvatarUrl" alt="" />
          </div>
          <div class="right">
            <div class="phone">
              {{ userInfo.Phone == "" ? "无数据" : userInfo.Phone }}
            </div>
            <div class="text">
              <img src="./images/编组 11@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="jhm" @click.stop="fn()">激活码开通VIP</div>
      </div>
      <div class="vipContent">
        <div class="vipLeft">
          <div class="vipEquity">
            <img src="./images/编组 9@2x.png" alt="" />
          </div>
          <div class="vipEquityText">
            <div>
              <span class="tt">适用考生：</span>
              <span>新高考省份考生 (不含艺术体育类)</span>
            </div>
            <div>
              <span class="tt">适用批次：</span>
              <span>普通类（不包含提前批）</span>
            </div>
            <div>
              <span class="tt">使用日期：</span>
              <span
                >有效期一年，有效期至{{ new Date().getFullYear() + 1 }}-{{
                  new Date().getMonth() + 1
                }}-{{ new Date().getDate() }}</span
              >
            </div>
            <div>
              <span class="tt">购买须知：</span>
              <span
                >系统仅提供志愿填报参考服务，招生计划与录取
                数据请以学校官方或考试院公布为准。正式填报请前往考试
                院官网，并根据官方信息，做好志愿核对。</span
              >
            </div>
          </div>
        </div>
        <div class="vipright">
          <div class="money">¥ 298</div>
          <div class="payment">
            <div class="payTab">
              <div class="wx">
                <span></span>
                <span>微信支付</span>
              </div>
              <!-- <div class="zfb">
                                <span></span>
                                <span>支付宝支付</span>
                            </div> -->
            </div>
            <div class="palyCode">
              <img :src="code" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCustomerInfoById } from "@/api/home";
import { getId } from "@/utils/auth.js";
import { getVipCheckRoles, CheckRoles } from "@/api/vip";
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      IsVip: false,
      timerId: null, // 存放计时器ID的变量
      userInfo: {},
      code: "",
      dialogVisible: false,
      show: false, // 控制遮罩层的显示与隐藏
      showButtonTransition: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.timerId); // 清除计时器
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getUserInfo();
  },

  methods: {
    startTimer() {
      this.timerId = setInterval(() => {
        // 这里写需要执行的逻辑
        this.getVIP();
        if (this.IsVip) {
          this.dialogVisible = false;
          this.show = false;
          clearInterval(this.timerId); // 清除计时器
          this.$message.success("您已支付成功");
          this.getUserInfo();
        }
      }, 3000); // 设置间隔为5秒
    },
    async getVIP() {
      const res = await getVipCheckRoles({
        CustomerId: this.$store.state.user.studentInfo.Id,
      });
      console.log("会员权限", res.data);
      this.IsVip = res.data.IsVip;
    },
    fn() {
      this.$message.error("功能暂未开放，请耐心等待");
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById({
        Id: getId(),
      });
      this.userInfo = res.data.response;
      this.$store.dispatch("user/removeStudentInfo");
      this.$store.commit("user/setStudentInfo", res.data.response);
      sessionStorage.setItem(
        "setStudentInfo",
        JSON.stringify(res.data.response)
      );
    },
    handleScroll() {
      this.scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (this.scrollPosition > 500) {
        this.showButtonTransition = true;
      } else {
        this.showButtonTransition = false;
      }
    },
    clickSHo() {
      this.dialogVisible = true;
      this.show = true;
      this.getOpent();
    },
    showWs() {
      this.show = false;
      this.dialogVisible = false;
      clearInterval(this.timerId);
    },
    async getOpent() {
      const res = await CheckRoles({
        PayType: 0,
        CustomerId: this.$store.state.user.studentInfo.Id,
        ProductId: 5,
      });
      const bufferUrl = btoa(
        new Uint8Array(res.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      this.code = "data:image/png;base64," + bufferUrl;
      // sessionStorage.setItem('code', JSON.stringify(this.code))
      this.startTimer();
    },
  },
};
</script>

<style lang="less" scoped>
.fade-in {
  transition: opacity 1s ease-out;
  /* 过渡效果 */
  opacity: 1;
  /* 默认不透明 */
}

img {
  width: 100%;
  height: 100%;
}

.fade-in {
  transition: opacity 1s ease-out;
  /* 过渡效果 */
  opacity: 1;
  /* 默认不透明 */
}

.OpenVip {
  width: 1382px;
  height: 164px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("./images/编组 4@2x.png");
  background-size: 100%;

  .op {
    position: absolute;
    bottom: 50%;
    transform: translateY(70%);
    right: 125px;
    height: 61px;
    display: flex;
    align-items: baseline;

    .lVip {
      font-size: 28px;
      color: #ffffff;
      margin-right: 119px;
    }

    .rVip {
      display: flex;
      align-items: baseline;

      .a {
        width: 119px;
        font-size: 36px;
        font-family: AaHouDiHei;
        color: #ffffff;
      }

      .kt {
        cursor: pointer;
        width: 170px;
        height: 61px;
        background: #f7e7c2;
        border-radius: 31px;
        text-align: center;
        line-height: 61px;
        font-weight: bold;
        color: #672408;
        font-size: 28px;
      }
    }
  }
}

.box {
  box-sizing: border-box;
  background: #f5f4f8;
}

.content {
  box-sizing: border-box;
  width: 1343px;
  margin: 0 auto;
  padding: 31px 33px 21px 33px;
  background: #fff;
}

.open {
  position: relative;
  height: 395px;
  background-image: url("./images/编组\ 2备份@2x.png");
  background-size: 100%;

  .vip {
    position: absolute;
    right: 37px;
    bottom: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .money {
      width: 260px;
      height: 88px;
      font-size: 60px;
      font-family: AaHouDiHei;
      color: #f5d09b;
      line-height: 88px;
      text-align: center;
    }

    .text {
      font-size: 14px;
      color: #d7e9ff;
    }

    .but {
      cursor: pointer;
      margin-top: 55px;
      text-align: center;
      line-height: 60px;
      font-weight: bold;
      color: #672408;
      font-size: 28px;
      width: 397px;
      height: 60px;
      background: linear-gradient(141deg, #f7e7c2 0%, #ecc479 100%);
      border-radius: 10px;
    }
  }
}

.detailsPicture {
  width: 1343px;
  margin: 0 auto;
  box-sizing: border-box;
}

.need-to-know {
  box-sizing: border-box;
  width: 1340px;
  margin: 0 auto;
  height: 600px;
  background: #ffffff;
  padding: 35px 33px 9px 65px;

  .title {
    font-size: 32px;
    font-weight: 600;
    color: #d12317;
    margin-bottom: 35px;
  }

  div {
    font-size: 24px;
    color: #333333;
    margin-bottom: 15px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.vipDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  padding: 24px 25px 30px 25px;
  background-image: url("./images/编组 3@2x.png");
  background-size: 100%;
  border-radius: 25px;
  z-index: 1999;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;

      .photo {
        width: 80px;
        height: 80px;
        margin-right: 14px;
        border-radius: 50%;
        overflow: hidden;
      }

      .right {
        .phone {
          width: 205px;
          font-size: 30px;
          font-weight: 600;
          color: #333333;
        }

        .text {
          text-align: center;
          line-height: 34px;
          font-size: 18px;
          font-weight: normal;
          color: #ffffff;
          width: 205px;
          height: 34px;
          background: linear-gradient(151deg, #387bff 0%, #6b4dff 100%);
          border-radius: 17px;
        }
      }
    }

    .jhm {
      cursor: pointer;
      width: 169px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background: linear-gradient(to right, #595263, #282537);
      border-radius: 10px;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .vipContent {
    display: flex;
    justify-content: space-between;

    .vipLeft {
      .vipEquity {
        width: 600px;
        height: 353px;
      }

      .vipEquityText {
        box-sizing: border-box;
        width: 560px;
        height: 258px;
        background: #ffffff;
        box-shadow: 0px 3px 20px 0px rgba(171, 122, 24, 0.18);
        border-radius: 21px;
        margin-left: 20px;
        padding: 23px 20px;
        font-size: 20px;
        color: #666666;

        div {
          margin-bottom: 15px;

          .tt {
            color: #333333;
            font-weight: 600;
          }
        }
      }

      .details {
        cursor: pointer;
        width: 170px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        background: linear-gradient(to right, #595263, #282537);
        border-radius: 10px;
        font-size: 20px;
        margin-left: (50%-20px);
        margin-top: 24px;
        color: #ffffff;
      }
    }

    .vipright {
      position: relative;
      width: 358px;
      height: 715px;
      background-image: url("./images/编组 7@2x.png");
      background-size: 100%;

      .money {
        position: absolute;
        top: 132px;
        width: 260px;
        left: 50%;
        transform: translateX(-50%);
        height: 88px;
        font-size: 60px;
        font-family: AaHouDiHei;
        color: #f5d09b;
        line-height: 45px;
        text-align: center;
      }

      .payment {
        position: absolute;
        top: 280px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .payTab {
          width: 260px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 22px;
          margin-bottom: 59px;
          font-size: 18px;

          .wx {
            cursor: pointer;
            width: 124px;
            height: 43px;
            background: #28c445;
            border-radius: 10px;
            color: #ffffff;
            text-align: center;
            line-height: 43px;
          }

          .zfb {
            cursor: pointer;
            width: 124px;
            height: 43px;
            background: #ffffff;
            border-radius: 10px;
            text-align: center;
            line-height: 43px;
            color: #1677ff;
          }
        }

        .palyCode {
          width: 158px;
          height: 158px;
        }
      }
    }
  }
}
</style>
