import {request} from "@/utils/request";

// 专业库 一级二级列表
export const uniGetMajorInfo = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/uniGetMajorInfo`,
    params,
  });
};

// 获取第三级
export const uniGetMajorThree = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/uniGetMajorThree`,
    params,
  });
};

// 专业搜索
export const SeachMajor = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/SeachMajor`,
    params,
  });
};

