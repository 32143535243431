import {request} from "@/utils/request";


// 获取前100的野鸡大学
export const GetFackUniversitys = (params) => {
  return request({
    method: "get",
    url: `/api/front/FackUniversity/GetFackUniversitys`,
    params,
  });
};

// 根据关键词查询所有院校列表
export const GetFack_Universitys = (params) => {
  return request({
    method: "get",
    url: `/api/front/FackUniversity/GetFack_Universitys`,
    params,
  });
};

// 获取批次线
export const GetBatchByYearArea = (params) => {
  return request({
    method: "get",
    url: `/api/front/Volunteer/GetBatchByYearArea`,
    params,
  });
};

// 获取历年份数据
export const GetSameScoreLine = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetSameScoreLine`,
    params,
  });
};

// 获取省份学年位次信息列表
export const GetScoreLine = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetScoreLine`,
    params,
  });
};
