import { request, request2 } from "@/utils/request";

// PC志愿表新增
export const PostPcVolunteer = (data) => {
  return request2({
    method: "post",
    url: `/api/volunTb/post`,
    data,
  });
};

// Pc端查询我的志愿列表
export const GetVolunteer = (data) => {
  return request2({
    method: "post",
    url: `/api/volunTb/Page`,
    data,
  });
};

// Pc删除志愿表
export const Delete = (id) => {
  return request({
    method: "delete",
    url: `/api/front/PcVolunteer/Delete/` + id,
  });
};

// Pc用户查询单个志愿表详情信息
export const VolunteerGet = (params) => {
  return request2({
    method: "get",
    url: `/api/volunTb/get/${params.locationcode}?id=` + params.id,
  });
};
// Pc专家查询单个志愿表详情信息
export const VolunteerGetZJ = (params) => {
  return request2({
    method: "get",
    url:
      `/api/sysWechatUserMapCarts/get/${params.locationcode}?id=` + params.id,
  });
};
// Pc修改志愿表
export function VolunteerPut(data) {
  return request2({
    url: "/api/volunTb/put",
    method: "POST",
    data,
  });
}

// Pc专家cop志愿表
export function copyTbZy(data) {
  return request2({
    url: "/api/sysWechatUserMapCarts/copyTbZy",
    method: "POST",
    data,
  });
}
// Pc专家cop列表
export const getZjlist = (params) => {
  return request2({
    method: "get",
    url: "/api/sysVolunteerTbSp/list",
    params,
  });
};
// Pc专家修改用户列表
export const UserMapCartsput = (data) => {
  return request2({
    method: "POST",
    url: "/api/sysWechatUserMapCarts/put",
    data,
  });
};
// Export下载
export const Export = (params) => {
  return request2({
    method: "get",
    url: "/api/sysWechatUserMapCarts/Export",
    params,
    responseType: "blob", // 确保获取到二进制流
  });
};
// Pc专家删除志愿表
export const DeleteZj = (data) => {
  return request2({
    method: "post",
    url: "/api/sysWechatUserMapCarts/delete",
    data,
  });
};
// 用户订单列表
export const userOrderList = (params) => {
  return request2({
    method: "get",
    url: "/api/sysWechatUserMapCarts/userOrderList",
    params,
  });
};
// 用户订单提交给专家
export const pushStatus = (data) => {
  return request2({
    method: "post",
    url: "/api/volunTb/pushStatus",
    data,
  });
};
