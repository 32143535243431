<template>
    <div class="box" style="max-width: 1200px;margin: 0 auto;">
        <div class="boder-radius5 registerbox-cont">
            <!-- 填写注册信息
            <div class="overfl" v-show="!isLogin">
                <div class="registerbox-cbox-txt clearfix" style="display: block; margin-left: 0px;">
                    <div class="registerbox-cbox-txt-title floatLeft">填写注册信息</div>
                </div>
                <div class="form">
                    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
                        <el-form-item label="手机号码：" prop="account">
                            <el-input v-model="form.account" class="input"></el-input>
                        </el-form-item>
                        <el-form-item label="短信验证码：" prop="account">
                            <div style="display: flex;">
                                <el-input v-model="form.account" class="input"></el-input>
                                <div style="cursor: pointer; color: #187cff;margin-left: 20px;">获取验证码</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="设置密码：" prop="password">
                            <el-input type="password" v-model="form.password" class="input" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码：" prop="password1">
                            <el-input type="password" v-model="form.password1" class="input" show-password></el-input>
                        </el-form-item>
                        <el-form-item style="margin-top: 100px;">
                            <el-button type="primary" @click="RegAccount('form')" style="width: 260px;">注册</el-button>
                            <br />
                            <span style="color: #187cff; cursor: pointer;" @click="login()">已有账号？点击返回</span>
                        </el-form-item>
                    </el-form>
                </div>
            </div> -->
            <!-- 完善基础信息 -->
            <div class="overfl">
                <div class="registerbox-cbox-txt clearfix" style="display: block; margin-left: 0px;">
                    <div class="registerbox-cbox-txt-title floatLeft">完善基础信息</div>
                    <div style="text-align: right;color: #ccc;">注：以上信息一旦提交不能更改</div>
                </div>
                <div class="form">
                    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
                        <el-form-item label="学生姓名：">
                            <el-input v-model="form.name" class="input"></el-input>
                        </el-form-item>
                        <el-form-item label="性别：">
                            <div>
                                <el-radio-group v-model="form.sex">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="2">女</el-radio>
                                </el-radio-group>
                                <span style="color: red; margin-left: 20px;">* 性别会影响专业推荐精准度，请如实填写</span>
                            </div>
                        </el-form-item>
                        <div style="display: flex;">
                            <!-- 省 -->
                            <el-form-item label="高考省份:" prop="province">
                                <el-select v-model="form.province" placeholder="请选择" @change="provinceChange($event)">
                                    <el-option :label="item.Name" :value="item.Code" v-for="item in provinceList"
                                        :key="item.Id"></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- 市 -->
                            <el-form-item prop="city" class="aaaa">
                                <el-select style="margin-left: 20px;" v-model="form.city" placeholder="请选择"
                                    @change="cityChange($event)">
                                    <el-option :label="item.Name" :value="item.Code" v-for="item in cityList"
                                        :key="item.Id"></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- 区 -->
                            <el-form-item prop="district" class="aaaa">
                                <el-select style="margin-left: 20px;" v-model="form.district" placeholder="请选择"
                                    @change="districtChange($event)">
                                    <el-option :label="item.Name" :value="item.Code" v-for="item in districtList"
                                        :key="item.Id"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <el-form-item label="高考年份:" prop="year">
                            <el-select v-model="form.year" placeholder="请选择高考年份">
                                <el-option :label="item.name" :value="item.code" v-for="item in selectInit.lizations"
                                    :key="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="就读高中:" prop="SchoolName">
                            <el-select v-model="form.SchoolName" placeholder="请选择">
                                <el-option :label="item.SchoolName" :value="item.SchoolName" v-for="item in schoolList"
                                    :key="item.Id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="margin-top: 100px;">
                            <el-button type="primary" @click="RegAccount('form')" style="width: 260px;">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetRegionList, GetHighSchoolRank } from "@/api/login";
import { UpdateCustomer, GetSeVolunteerInitialization } from "@/api/home";
import { getId } from "@/utils/auth.js";
export default {
    name: 'VoluntaryReportingIndex',
    data() {
        return {
            provinceList: [],//省
            cityList: [],//市
            districtList: [],//区
            schoolList: [],//学校
            listInit: [],//年份
            selectInit: {},//所选省份的年份
            searchSchoolObj: {//搜索学校参数
                Province: '',
                City: '',
                Area: '',
                Name: ''
            },
            form: {//完善信息参数
                name: '',
                sex: '1',
                province: '',
                city: '',
                district: '',
                year: '',
                SchoolName: '',
            },
            rules: {
                province: [
                    { required: true, message: '请选择高考省份', trigger: 'change' }
                ],
                city: [
                    { required: true, message: '请选择高考市', trigger: 'change' }
                ],
                district: [
                    { required: true, message: '请选择高考区', trigger: 'change' }
                ],
                year: [
                    { required: true, message: '请选择高考年份', trigger: 'change' }
                ],
                SchoolName: [
                    { required: false, message: '请选择就读高中', trigger: 'change' }
                ],
            }
        }
    },
    mounted() {
        this.getRegionList()
        this.getSeVolunteerList()
    },
    methods: {
        // 更新信息
        async confirm() {
            await UpdateCustomer({ studentId: getId(), nickName: this.form.name, gender: this.form.sex, year: this.form.year, areaName: this.searchSchoolObj.Province, schoolName: this.form.SchoolName })
            this.$message({
                showClose: true,
                message: '恭喜你，账户已注册✔',
                type: 'success'
            });
            this.$router.push('/Home/FirstPage')
        },
        RegAccount(reffrom) { //【注册】
            //数据向后端发送进行验证
            //$refs是一个引用
            this.$refs[reffrom].validate((valid) => {
                if (valid) {
                    console.log('提交的信息', this.form);
                    this.confirm()
                }
            });
        },
        // 获取高考年份type
        async getSeVolunteerList() {
            const res = await GetSeVolunteerInitialization()
            this.listInit = res.data.response
        },
        // 省change
        provinceChange(e) {
            var obj = {};
            obj = this.provinceList.find(function (item) {
                return item.Code === e;
            })
            this.selectInit = this.listInit.find(function (item) {
                return item.ProvinceName === obj.Name;
            })
            this.searchSchoolObj.Province = obj.Name

            this.cityList = []
            this.districtList = []
            this.form.city = ''
            this.form.district = ''
            this.form.year = ''
            this.form.SchoolName = ''
            this.getCityList(e)
        },
        // 市change
        cityChange(e) {
            var obj = {};
            obj = this.cityList.find(function (item) {
                return item.Code === e;
            })
            this.searchSchoolObj.City = obj.Name

            this.districtList = []
            this.schoolList = []
            this.form.district = ''
            this.form.SchoolName = ''
            this.getSchoolList(1)
            this.getDistrictList(e)
        },
        // 区change
        districtChange(e) {
            var obj = {};
            obj = this.districtList.find(function (item) {
                return item.Code === e;
            })
            this.searchSchoolObj.Area = obj.Name

            this.form.SchoolName = ''
            this.getSchoolList()
        },
        // 获取的省数据
        async getRegionList() {
            const res = await GetRegionList()
            this.provinceList = res.data.response
        },
        // 获取的市数据
        async getCityList(Code) {
            const res = await GetRegionList({ Code })
            this.cityList = res.data.response
        },
        // 获取的区数据
        async getDistrictList(Code) {
            const res = await GetRegionList({ Code })
            this.districtList = res.data.response
        },
        // 获取学校信息
        async getSchoolList(type) {
            // 1 市区 2区
            if (type == 1) {
                this.searchSchoolObj.Area = ''
            }
            const res = await GetHighSchoolRank({ ...this.searchSchoolObj })
            this.schoolList = res.data.response
        },
        // 重置
        reset() {
            this.form = {
                name: '',
                sex: '男',
                year: '',
                city: '',
                district: '',
                password1: '',
                SchoolName: '',
            }
        }
    }
}


</script>

<style lang="less" scoped>
.aaaa> ::v-deep .el-form-item__content {
    margin-left: 0 !important;
}

.el-input {
    width: 260px;
}

.el-button--primary {
    background: #187cff;
}


.registerbox-cont {
    padding-top: 40px;
    padding-left: 48px;
    width: 1200px;
    background-color: #fff;
    box-sizing: border-box;
    margin: auto;
    border-radius: 14px;
    padding-bottom: 50px;
    overflow: hidden;

    .registerbox-cbox-txt {
        display: inline-block;
        margin-right: 20px;
        margin-left: 10px;

        .registerbox-cbox-txt-title {
            font-size: 24px;
            color: #333;
            font-weight: 700;
        }
    }

}

.form {
    margin-top: 20px;

    ::v-deep input {
        width: 260px;
        height: 36px;
        text-indent: 1em;
        border-radius: 6px;
    }
}
</style>