<template>
  <div class="boxs">
    <div class="titles">账号信息</div>
    <div class="content">
      <div class="photos">
        <div class="texts">头像</div>
        <div class="photo">
          <el-upload
            list-type="picture-card"
            class="avatar-uploader"
            :action="''"
            :show-file-list="false"
            :before-upload="beforeUpload"
          >
            <!-- 回显图片 -->
            <img
              v-if="amendObj.AvatarUrl"
              :src="amendObj.AvatarUrl"
              class="avatar"
            />
            <!-- 上传按钮 -->
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </div>
      </div>
      <div class="item">
        <div class="texts">姓名</div>
        <div class="name">
          <!-- <span style="margin-right: 20px;">167****98771</span> -->
          <el-input
            v-model="amendObj.NickName"
            :disabled="showName"
            placeholder="请输入姓名"
          ></el-input>
          <i
            v-if="showName == true"
            style="cursor: pointer; margin-left: 20px"
            class="el-icon-edit"
            @click="showName = false"
          ></i>
          <span
            v-else
            @click="qd()"
            style="
              color: #187cff;
              margin-left: 20px;
              cursor: pointer;
              width: 100px;
            "
            >完成</span
          >
        </div>
      </div>
      <div class="item">
        <div class="texts">账号</div>
        <div class="name">
          <span>{{ userInfo.mobile }}</span>
        </div>
      </div>
      <!-- <div class="item">
        <div class="texts">设置/修改密码</div>
        <div class="password">
          <el-input
            v-model="amendObj.userPwd"
            placeholder="请输入密码"
          ></el-input>
        </div>
      </div> -->
      <div class="item">
        <div class="texts">注销账号</div>
        <div
          style="font-size: 20px; color: red; cursor: pointer"
          @click="shows()"
        >
          注销
        </div>
        <div
          style="
            font-size: 20px;
            color: #187cff;
            margin-left: 100px;
            cursor: pointer;
            width: 100px;
          "
          @click="amendInfo()"
        >
          保存
        </div>
      </div>
    </div>
    <div class="mask" v-if="show" @click="show = false"></div>
    <div class="pro" v-if="show">
      <div class="tit">注销账号</div>
      <div class="introductory">
        注销后，此账号所有数据将清空，并且数据<span
          style="color: #187cff; cursor: pointer"
          >无法找回</span
        >。
      </div>
      <div class="details">
        <p>内容包括但不限于：</p>
        <p>1. 此账号中模拟报志愿订单信息</p>
        <p>2. 此账号中的志愿表数据</p>
        <p>3. 此账号已完成的评测分析报告数据</p>
        <p>4. 此账号关注的院校、专业数据</p>
        <p>5. 此账号的资讯数据</p>
        <p>6. 此账号的用户信息数据</p>
        <p>7. 此账号的浏览记录</p>
      </div>
      <div class="accept">
        <div class="check">
          <el-checkbox v-model="checked"></el-checkbox>
        </div>
        <div>我了解并接受注销可能带来损失</div>
      </div>
      <div class="ut">
        <button class="qd" @click="logout()">确定注销</button>
        <button class="qx" @click="show = false">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetCustomerInfoById,
  UpdateCustomerAvatarUrl,
  UploadImg,
} from "@/api/home";
import { AccountCancel } from "@/api/vip";
import { getServiceToken } from "@/utils/auth.js";

export default {
  name: "VoluntaryReportingUserInfoModification",

  data() {
    return {
      showName: true,
      show: false,
      checked: false,
      userInfo: {},
      amendObj: {
        Id: "",
        userPwd: "",
        AvatarUrl: "",
        NickName: "",
        service_token: getServiceToken(),
      },
    };
  },

  mounted() {
    this.getUserInfo();
  },

  methods: {
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return;
      }
      const fileData = new FormData();
      fileData.append("file", file);
      console.log([...fileData.entries()]);
      // upload为上传的接口
      UploadImg(fileData).then((res) => {
        // 对返回的图片地址进行回显
        this.amendObj.AvatarUrl = res.data.result.url;
        this.amendInfo();
      });
      // 阻止传到本地浏览器
      return false;
    },
    shows() {
      this.show = true;
    },
    qd() {
      this.showName = true;
      this.amendInfo();
    },
    async logout() {
      if (this.checked == false) {
        this.$message.error("请勾选并阅读条例后在尝试");
        return false;
      }
      this.show = false;
      const res = await AccountCancel();
      if (res.data.response) {
        this.$message.success("注销成功~");
        setTimeout(() => {
          window.location.href = process.env.VUE_APP_SSO_URL;
        }, 2000);
      }
      console.log("注销");
    },
    // 修改个人信息
    async amendInfo() {
      if (this.amendObj.NickName !== "") {
        const res = await UpdateCustomerAvatarUrl({
          avatar: this.amendObj.AvatarUrl,
          nickName: this.amendObj.NickName,
        });
        console.log("修改数据", res);
        this.$message.success("修改成功");
        this.getUserInfo();
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
      } else {
        this.$message.error("姓名不能为空");
      }
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.userInfo = res.data.result;
      this.amendObj.Id = this.userInfo.Id;
      this.amendObj.NickName = this.userInfo.nickName;
      this.amendObj.AvatarUrl = this.userInfo.avatar;
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}

.avatar-uploader {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: 100%;
  height: 100%;
}

.boxs {
  background: #fff;
}

.titles {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 73px;
}

.content {
  .photos {
    display: flex;
    align-items: center;
    margin-bottom: 77px;

    .photo {
      width: 80px;
      height: 80px;
      overflow: hidden;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.texts {
  width: 130px;
  font-size: 20px;
  color: #333333;
  margin-right: 60px;
}

.name {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #999999;
}

.password {
  font-size: 20px;
  color: #333333;
  display: flex;
  align-items: center;

  ::v-deep .el-input__inner {
    background: #d7e0fa;
  }

  .pass {
    display: inline-block;
    padding: 6px 14px;
    min-width: 300px;
    background: #d7e0fa;
  }
}

.pro {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 807px;
  height: 675px;
  background: #ffffff;
  border-radius: 25px;
  z-index: 9999;
  padding: 38px 110px 43px 110px;
  box-sizing: border-box;

  .tit {
    margin: 0 auto;
    width: 88px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
  }

  .introductory {
    font-size: 16px;
    color: #333333;
    margin: 45px 0 32px 0;
  }

  .details {
    font-size: 16px;
    margin-bottom: 16px;

    p {
      margin-bottom: 20px;
    }
  }

  .accept {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 37px;

    .check {
      margin-right: 20px;
    }
  }

  .ut {
    width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 16px;

    .qd {
      cursor: pointer;
      width: 120px;
      height: 45px;
      background: #187cff;
      border-radius: 10px;
      text-align: center;
      line-height: 45px;
      margin-right: 25px;
      border: none;
      color: #fff;
    }

    .qx {
      cursor: pointer;
      width: 120px;
      height: 45px;
      background: #f5f5f5;
      border-radius: 10px;
      text-align: center;
      line-height: 45px;
      border: none;
      color: #187cff;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}
</style>
