import {request} from "@/utils/request";


// 获取省市区
export const GetRegionList = (params) => {
  return request({
    method: "get",
    url: `/api/SysRegion/GetRegionList`,
    params,
  });
};

// 获取高中学校名
export const GetHighSchoolRank = (params) => {
  return request({
    method: "get",
    url: `/api/HighSchoolRank/Get`,
    params,
  });
};
