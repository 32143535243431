import {request2} from "@/utils/request";


// 获取院校库
export const OneSubmitGo = (data) => {
  return request2({
    method: "POST",
    url: `/api/PlanPro/v2/oneKey`,
    data,
  });
};
