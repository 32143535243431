<template>
  <div class="box">
    <div class="bane">
      <img src="./images/zjbaner.png" alt="" />
    </div>
    <div class="content">
      <div class="list">
        <div
          class="teacheritem"
          v-for="(it, idx) in busSpecialistGrouList"
          :key="idx"
        >
          <div class="teachername">{{ it.name }}</div>
          <div class="expertisebox">
            <div
              class="expertiseitem"
              v-for="(its, idxs) in it.majorTags"
              :key="idxs"
            >
              {{ its }}
            </div>
          </div>
          <div class="introbox">
            <div class="introboxo">
              <div class="introitem">
                <span class="greypoint"></span
                ><span class="greytxt">{{ it.businessScope }}</span>
              </div>
            </div>
          </div>
          <div class="yuyuenow" @click="startCountdown()">立即预约</div>
          <!---->
          <img :src="it.pic" alt="" class="absoimg" />
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="subscribe"
      width="304px"
      :before-close="handleClose"
      center
    >
      <div class="diaB">
        <div class="icimg">
          <img src="./images/cg.png" alt="" />
        </div>
        <div class="ictext">预约成功</div>
        <div class="ictext1">老师会联系您，请稍后！</div>
        <div class="miao">{{ countdown }}秒后自动关闭</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { busSpecialistGroup } from "@/api/News";
export default {
  name: "VoluntaryReportingIndex",
  data() {
    return {
      countdown: 3, // 倒计时初始值
      subscribe: false, //预约显示隐藏
      timer: null, // 定时器
      busSpecialistGrouList: [],
    };
  },

  mounted() {
    this.BusSpecialistGroup();
  },

  methods: {
    // 专家列表信息
    async BusSpecialistGroup() {
      const res = await busSpecialistGroup();
      this.busSpecialistGrouList = res.data.result;
    },
    startCountdown() {
      // 如果有正在进行的倒计时，清除它
      if (this.timer) {
        clearInterval(this.timer);
      }

      // 重置倒计时和状态
      this.countdown = 3;
      this.subscribe = true;

      // 开始倒计时
      this.timer = setInterval(() => {
        this.countdown--;

        // 倒计时结束
        if (this.countdown <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.timer = null; // 重置定时器引用
          this.subscribe = false; // 设置订阅状态为 false
        }
      }, 1000);
    },
    beforeDestroy() {
      // 确保组件销毁时清除定时器
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
.box {
  width: 100%;
  .bane {
    min-width: 1920px;
    height: 330px;
  }
  .content {
    width: 1280px;
    margin: 0 auto;
    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 44px;
      .teacheritem:nth-child(3n) {
        margin-right: 0; /* 每行的最后一个元素去掉右边距 */
      }
      .teacheritem {
        background: linear-gradient(
          rgb(222, 231, 248) 0%,
          rgb(255, 255, 255) 30%
        );
        box-sizing: border-box;
        width: calc(25% - 16px); /* 每个元素的宽度，减去间距 */
        margin-right: 16px;
        margin-bottom: 16px;
        width: 416px;
        height: 316px;
        background-size: 100%;
        border-radius: 16px;
        margin-right: 16px;
        position: relative;
        box-shadow: 0 14px 20px 1px rgba(0, 0, 0, 0.04);
        .teachername {
          margin-top: 20px;
          font-weight: 600;
          font-size: 20px;
          color: #333;
          width: 48%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
        }
        .expertisebox {
          position: absolute;
          right: 13%;
          width: 226px;
          display: flex;
          flex-wrap: wrap;
          .expertiseitem {
            width: 108px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            margin-right: 4px;
            border-radius: 4px;
            background: hsla(0, 0%, 100%, 0.5);
            margin-top: 4px;
          }
        }
        .introbox {
          position: absolute;
          bottom: 55px;
          left: 0;
          height: 133px;
          background: #fff;
          overflow: hidden;
          padding: 20px;
          box-sizing: border-box;
          width: 100%;
          .introboxo {
            height: 94px;
            overflow-y: auto;
            .introitem {
              font-size: 14px;
              color: #888;
              margin-top: 6px;
              position: relative;
              .greypoint {
                width: 4px;
                height: 4px;
                background: #aaa;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 7px;
                left: 2px;
              }
              .greytxt {
                margin-left: 12px;
                width: 350px;
                display: inline-block;
                line-height: 18px;
                overflow: hidden;
              }
            }
          }
        }
        .yuyuenow:hover {
          background: linear-gradient(91deg, #187cff, #076cef);
          color: #fff;
        }
        .yuyuenow {
          width: 100%;
          height: 54px;
          line-height: 54px;
          border-top: 1px solid #eee;
          font-size: 16px;
          color: #333;
          text-align: center;
          border-radius: 0 0 16px 16px;
          position: absolute;
          bottom: 0;
          left: 0;
          cursor: pointer;
        }
        .absoimg {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          left: 32px;
          bottom: 215px;
        }
      }

      .subscribe {
        cursor: pointer;
        margin-top: 21px;
        width: 356px;
        height: 45px;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        color: #333333;
        border: 1px solid #e1e1e1;

        a {
          display: block;
          text-decoration: none;
          color: #333333;
        }
      }

      .subscribe:hover {
        cursor: pointer;
        margin-top: 21px;
        width: 356px;
        height: 45px;
        background: #187cff;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        color: #ffffff;

        a {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
