var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"assessItem"},[_c('div',{staticClass:"title"},[_vm._v("学职定位")]),_c('div',{staticClass:"cpList"},[_c('div',{staticClass:"cp1",on:{"click":function($event){return _vm.goUrl('carrerpostion')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"cp1",on:{"click":function($event){return _vm.goUrl('carrerpostion')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"cp1",on:{"click":function($event){return _vm.goUrl('carrerpostion')}}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"cp1",on:{"click":function($event){return _vm.goUrl('carrerpostion')}}},[_vm._m(7),_vm._m(8)])])]),_c('div',{staticClass:"assessItem"},[_c('div',{staticClass:"title"},[_vm._v("心理健康")]),_c('div',{staticClass:"cpList"},[_c('div',{staticClass:"cp2",on:{"click":function($event){return _vm.goUrl('psychology')}}},[_vm._m(9),_vm._m(10)]),_c('div',{staticClass:"cp2",on:{"click":function($event){return _vm.goUrl('psychology')}}},[_vm._m(11),_vm._m(12)]),_c('div',{staticClass:"cp2",on:{"click":function($event){return _vm.goUrl('psychology')}}},[_vm._m(13),_vm._m(14)]),_c('div',{staticClass:"cp2",on:{"click":function($event){return _vm.goUrl('psychology')}}},[_vm._m(15),_vm._m(16)])])]),_c('div',{staticClass:"assessItem"},[_c('div',{staticClass:"title"},[_vm._v("学习状态")]),_c('div',{staticClass:"cpList"},[_c('div',{staticClass:"cp3",on:{"click":function($event){return _vm.goUrl('studystatus')}}},[_vm._m(17),_vm._m(18)]),_c('div',{staticClass:"cp3",on:{"click":function($event){return _vm.goUrl('studystatus')}}},[_vm._m(19),_vm._m(20)]),_c('div',{staticClass:"cp3",on:{"click":function($event){return _vm.goUrl('studystatus')}}},[_vm._m(21),_vm._m(22)]),_vm._m(23)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bane"},[_c('img',{attrs:{"src":require("./images/zjbaner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("性格测评（MBTI）")]),_c('div',{staticClass:"jc"},[_vm._v(" 帮助学生根据个人特点科学选择高考志愿，匹配兴趣与职业规划，提升录取机会。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("兴趣测评（Holland）")]),_c('div',{staticClass:"jc"},[_vm._v(" 基于职业兴趣类型，帮助学生了解自身兴趣，指导志愿选择，提升职业匹配度。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("能力测评（MIDAS）")]),_c('div',{staticClass:"jc"},[_vm._v(" 多元智能理论的测评工具，旨在评估个体在八种不同智能领域的能力。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("职业锚（Career ）")]),_c('div',{staticClass:"jc"},[_vm._v(" 个体在职业生涯中最重要的价值观和动机，这些价值观和动机会影响其职业决策。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("SAS焦虑测评")]),_c('div',{staticClass:"jc"},[_vm._v(" 常用于评估个体焦虑水平的心理测量工具。帮助测量个体在一段时间内的焦虑症状 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/11.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("SDS抑郁测评")]),_c('div',{staticClass:"jc"},[_vm._v(" 帮助学生识别潜在的抑郁症状，特别是在高考等压力较大的时期。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/22.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("SCL-90症状自评")]),_c('div',{staticClass:"jc"},[_vm._v(" 学生能够及时发现潜在的心理问题，采取有效的干预措施，改善心理健康状态。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/33.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("中学生心理健康自评")]),_c('div',{staticClass:"jc"},[_vm._v(" 能有效帮助学生提高心理健康意识，预防和应对学业、成长等带来的心理困扰。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/44.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("Solomon学习风格")]),_c('div',{staticClass:"jc"},[_vm._v(" 了解自己最有效的学习方式，选择符合自己学习风格的专业和职业方向。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/111.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("学生考试焦虑症测试")]),_c('div',{staticClass:"jc"},[_vm._v(" 了解自己在的焦虑水平，及时采取应对措施，帮助缓解考试压力，提高考试表现。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/222.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tit"},[_vm._v("学习技能")]),_c('div',{staticClass:"jc"},[_vm._v(" 学习过程中使用的各种策略和技巧，帮助他们更高效地获取、处理和应用知识。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./images/333.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cp4"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./images/kk.png"),"alt":""}})]),_c('div',{staticClass:"btn"},[_vm._v("更多测评敬请期待")])])
}]

export { render, staticRenderFns }