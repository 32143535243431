import {request,request2} from "@/utils/request";


// 所有新闻列表带分页
export const GetPageNews = (params) => {
  return request({
    method: "get",
    url: `/api/front/NewsInfo/GetPageNews`,
    params,
  });
};

// 根据Id 获取新闻详情
export const GetDetail = (params) => {
  return request({
    method: "get",
    url: `/api/front/NewsInfo/GetDetail`,
    params,
  });
};

// 获取新闻分类
export const GETNewsCategory = (params) => {
  return request({
    method: "get",
    url: `/api/front/NewsInfo/GETNewsCategory `,
    params,
  });
};
// 专家列表信息
export const busSpecialistGroup = (params) => {
  return request2({
    method: "get",
    url: `/api/busSpecialistGroup/list`,
    params,
  });
};
// H5产品列表
export const speciallist = (params) => {
  return request2({
    method: "get",
    url: `/api/busSpecialistGroup/speciallist`,
    params,
  });
};
// 专家订单列表
export const specialistGroupOrderList = (params) => {
  return request2({
    method: "get",
    url: `/api/sysWechatUserMapCarts/specialistGroupOrderList`,
    params,
  });
};
// 专家订单列表
export const getCustomerTbList = (params) => {
  return request2({
    method: "get",
    url: `/api/sysWechatUserMapCarts/getCustomerTbList`,
    params,
  });
};