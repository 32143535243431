<template>
  <div class="box">
    <div class="header___oUVpS">
      <div class="header-title___OWNrG">专业知识库</div>
      <div class="header-search___BbHPB">
        <span class="header-search-input___gKtlk" style="width: 100%">
          <span class="prefix">
            <i class="el-icon-search"></i>
          </span>
          <input
            v-model="Name"
            placeholder="搜索你感兴趣的专业"
            @keyup.enter="serve()"
          />
        </span>
        <button class="header-search-button___pJcBm" @click="serve()">
          查询
        </button>
      </div>
    </div>
    <!--  -->
    <div class="bottom___cfv6K" v-if="select || Name == ''">
      <!--  -->
      <div class="tab___TzXMI">
        <div class="tab-item___bn9MO" v-for="(item, idx) in tabList" :key="idx">
          <span
            :class="
              SchoolLevel == item.type
                ? 'tab-item-name___dDFnl tab-item-active___l04vh'
                : 'tab-item-name___dDFnl'
            "
            @click="qhTab(item.type)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <!--  -->
      <div class="selector___KmG2p">
        <div class="subject-list___VKbr0">
          <div class="title___hhagm" style="border: none">门类</div>
          <div
            :class="
              selectRootId == item.RootId
                ? 'subject-list-item___armnB subject-list-item-active___oUd_J'
                : 'subject-list-item___armnB'
            "
            v-for="(item, index) in list"
            :key="index"
            @click="category(item, index)"
          >
            <img
              v-if="selectRootId == item.RootId"
              src="./images/1.png"
              alt="图片"
              class="active-image___e4XD0"
            />
            {{ item.FirstName }}
          </div>
        </div>
        <!--  -->
        <div class="subject-list___VKbr0">
          <div class="title___hhagm" style="border: none">专业类</div>
          <div
            :class="
              selectSecondId == item.SecondId
                ? 'subject-list-item___armnB subject-list-item-active___oUd_J'
                : 'subject-list-item___armnB'
            "
            v-for="(item, index) in secondaryList"
            :key="index"
            @click="major(item, index)"
          >
            <img
              v-if="selectSecondId == item.SecondId"
              src="./images/1.png"
              alt="图片"
              class="active-image___e4XD0"
            />
            {{ item.MajorName }}
          </div>
        </div>
        <!--  -->
        <div class="major-list___dQ4gN">
          <div class="title___hhagm">专业名称</div>
          <div class="major-list-wrap____DVl_">
            <div
              class="major-list-item___VibuW"
              style="cursor: pointer"
              v-for="(item, idx) in specialtyList"
              :key="idx"
            >
              <div
                class="major-list-item___VibuW"
                style="cursor: pointer; width: 100%"
                v-if="SchoolLevel !== 3"
                @click="goDetail(item.Id)"
              >
                <div class="major-list-item-field___OcMjQ">
                  <div class="major-list-item-name___ibG2t">
                    {{ item.Name }}
                  </div>
                  <div class="major-list-item-desc___ceRaB">
                    <div>专业代码：{{ item.Code }}</div>
                    <!-- <div>修业年限：四年</div>
                                    <div>授予学位：哲学学士</div> -->
                  </div>
                </div>
                <div class="major-list-item-field___OcMjQ">
                  <div class="major-list-item-button___amzKV">查看开设院校</div>
                </div>
              </div>
              <div class="major-list-item___VibuW" style="width: 100%" v-else>
                <div class="major-list-item-field___OcMjQ">
                  <div class="major-list-item-name___ibG2t">
                    {{ item.Name }}
                  </div>
                  <div class="major-list-item-desc___ceRaB">
                    <div>专业代码：{{ item.Code }}</div>
                    <!-- <div>修业年限：四年</div>
                                    <div>授予学位：哲学学士</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px" v-if="specialtyList.length == 0">
              暂无数据
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom___cfv6K" v-else>
      <div class="header___WH_g7">
        <div class="header-title___eEYG1" v-if="SchoolLevel == 1">
          本科(普通教育)
        </div>
        <div class="header-title___eEYG1" v-if="SchoolLevel == 2">
          高职（专科）
        </div>
        <div class="header-title___eEYG1" v-if="SchoolLevel == 3">
          本科（职业教育）
        </div>
      </div>
      <!-- <div class="no-result___mB_JR">查询无结果</div> -->
      <div class="result___oxjNu" v-if="servelist.length !== 0">
        <div class="major-list___a3EDB" v-if="SchoolLevel == 1">
          <div class="title___xmzED">专业名称</div>
          <div class="major-list-wrap___ZGTgc">
            <div
              class="major-list-item___ixB37"
              v-for="(it, idx) in servelist"
              :key="idx"
              @click="goDetail(it.Id)"
            >
              <div class="major-list-item-field___w0mcw">
                <div class="major-list-item-name___zYQN3">
                  <div><span v-html="hightLightText(it.Name)"></span></div>
                </div>
                <div class="major-list-item-desc___hztoS">
                  <div>专业代码：{{ it.Code }}</div>
                  <!-- <div>修业年限：四年</div>
                                    <div>授予学位：法学学士</div> -->
                </div>
              </div>
              <div class="major-list-item-button___NWOmH">查看开设院校</div>
            </div>
          </div>
        </div>
        <div class="major-list___a3EDB" v-else>
          <div class="title___xmzED">专业名称</div>
          <div class="major-list-wrap___ZGTgc">
            <div
              class="major-list-item___ixB37"
              v-for="(it, idx) in servelist"
              :key="idx"
            >
              <div class="major-list-item-field___w0mcw">
                <div class="major-list-item-name___zYQN3">
                  <div><span v-html="hightLightText(it.Name)"></span></div>
                </div>
                <div class="major-list-item-desc___hztoS">
                  <div>专业代码：{{ it.Code }}</div>
                  <!-- <div>修业年限：四年</div>
                                    <div>授予学位：法学学士</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-result___mB_JR" v-else>查询无结果</div>
      <!-- <div class="header___WH_g7">
                <div class="header-title___eEYG1">高职（专科）</div>
            </div>
            <div class="result___oxjNu">
                <div class="major-list___a3EDB">
                    <div class="title___xmzED">专业名称</div>
                    <div class="major-list-wrap___ZGTgc">
                        <div class="major-list-item___ixB37" style="border: none;">
                            <div class="major-list-item-field___w0mcw">
                                <div class="major-list-item-name___zYQN3">
                                    <div><span></span><span class="major-list-item-key___hVkkP">现代农业技术</span><span></span>
                                    </div>
                                </div>
                                <div class="major-list-item-desc___hztoS">
                                    <div>专业代码：410103</div>
                                    <div>修业年限：三年</div>
                                </div>
                            </div>
                            <div class="major-list-item-button___NWOmH">查看开设院校</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-result___mB_JR">查询无结果</div>
            <div class="header___WH_g7">
                <div class="header-title___eEYG1">本科（职业教育）</div>
            </div>
            <div class="result___oxjNu">
                <div class="major-list___a3EDB">
                    <div class="title___xmzED">专业名称</div>
                    <div class="major-list-wrap___ZGTgc">
                        <div class="major-list-item___ixB37" style="border: none;">
                            <div class="major-list-item-field___w0mcw">
                                <div class="major-list-item-name___zYQN3">
                                    <div><span></span><span class="major-list-item-key___hVkkP">现代农业技术</span><span></span>
                                    </div>
                                </div>
                                <div class="major-list-item-desc___hztoS">
                                    <div>专业代码：410103</div>
                                    <div>修业年限：三年</div>
                                </div>
                            </div>
                            <div class="major-list-item-button___NWOmH">查看开设院校</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-result___mB_JR">查询无结果</div> -->
    </div>
  </div>
</template>

<script>
import {
  uniGetMajorInfo,
  uniGetMajorThree,
  SeachMajor,
} from "@/api/CheckTheMajor";
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      Name: "",
      select: true,
      SchoolLevel: 1,
      selectRootId: "",
      selectSecondId: "",
      tabList: [
        {
          name: "本科(普通教育)",
          type: 1,
        },
        {
          name: "高职（专科）",
          type: 2,
        },
        {
          name: "本科（职业教育）",
          type: 3,
        },
      ],
      list: [],
      specialtyList: [],
      secondaryList: [],
      servelist: [],
    };
  },

  mounted() {
    //其他页面学校id
    console.log(this.$route.query.majorName);
    if (this.$route.query.majorName !== undefined) {
      this.Name = this.$route.query.majorName;
      this.serve();
    }
    this.getList();
  },

  methods: {
    hightLightText(text) {
      if (text) {
        const reg = new RegExp(this.Name, "gi"); // 动态正则表达式
        return text.replace(
          reg,
          `<span style="color:#187cff" class="major-list-item-key___hVkkP">${this.Name}</span>`
        ); // 使用replace替换
      }
    },
    goDetail(id) {
      const routeData = this.$router.resolve({
        path: "/Home/Detail",
        query: { id },
      });
      window.open(routeData.href, "_blank");
    },
    // 门类切换
    category(it, index) {
      this.selectRootId = it.RootId;
      this.secondaryList = this.list[index].SecondInfo;
      this.selectSecondId = this.secondaryList[0].SecondId;
      this.getDesignation();
    },
    // 专业切换
    major(it) {
      this.selectSecondId = it.SecondId;
      this.getDesignation();
    },
    // 搜索还是列表
    async serve() {
      this.select = false;
      const res = await SeachMajor({
        Name: this.Name,
      });
      this.servelist = res.data.response;
      console.log(res);
    },
    // 切换本科专科
    qhTab(type) {
      this.SchoolLevel = type;
      this.getList();
    },
    // 获取一级二级列表
    async getList() {
      const res = await uniGetMajorInfo({ SchoolLevel: this.SchoolLevel });
      this.list = res.data.response;
      this.selectRootId = res.data.response[0].RootId;
      this.secondaryList = res.data.response[0].SecondInfo;
      this.selectSecondId = res.data.response[0].SecondInfo[0].SecondId;
      this.getDesignation();
    },
    // 获取三级列表
    async getDesignation() {
      const res = await uniGetMajorThree({
        categoryclassid: this.selectSecondId,
      });
      this.specialtyList = res.data.response;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.box {
  min-height: calc(100vh - 160px);
  width: 100vw;
  position: relative;
  min-width: 1440px;
  overflow-x: auto;
}

.header___oUVpS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 201px;
  background-image: url(./images/searchbg.c5fdd44a.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .header-title___OWNrG {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    line-height: 44px;
    margin-bottom: 15px;
  }

  .header-search___BbHPB {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 850px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0 5px 10px rgba(30, 30, 30, 0.1);
    border-radius: 10px;

    .header-search-input___gKtlk {
      position: relative;
      align-items: center;
      width: 100%;
      min-width: 0;
      height: 100%;
      font-size: 14px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s;
      display: inline-flex;

      .prefix {
        margin-top: 5px;
        margin-right: 5px;
      }

      .prefix > i {
        font-size: 18px;
      }
    }

    input {
      height: 50px;
      width: 100%;
      padding: 0;
      font-size: inherit;
      border: none;
      outline: none;
    }

    .header-search-button___pJcBm {
      width: 142px;
      height: 50px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      background: #187cff;
      box-shadow: 0 5px 10px rgba(30, 30, 30, 0.1);
      border-radius: 0 10px 10px 0;
      cursor: pointer;
      border: none;
    }
  }
}

.bottom___cfv6K {
  position: relative;
  padding-top: 20px;
  padding-bottom: 89px;
  margin: 0 auto;
  width: 1200px;

  .tab___TzXMI {
    display: flex;
    width: 100%;
    height: 46px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;

    .tab-item___bn9MO {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      cursor: pointer;

      .tab-item-active___l04vh {
        font-weight: 500 !important;
        color: #187cff !important;
        border-color: #187cff !important;
      }

      .tab-item-name___dDFnl {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        font-size: 18px;
        color: #9d9d9d;
        height: 100%;
        border-bottom: 4px solid #fff;
        padding-bottom: 4px;
      }
    }
  }

  .selector___KmG2p {
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #e4e4e4;

    .subject-list___VKbr0 {
      width: 198px;
      border-right: 1px solid #e7e7e7;

      .title___hhagm {
        box-sizing: content-box;
        background-color: #f5f5f5;
        padding-left: 20px;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 500;
        color: #272727;
        border-bottom: 1px solid #e4e4e4;
      }

      .subject-list-item-active___oUd_J {
        box-sizing: border-box;
        color: #187cff;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;

        .active-image___e4XD0 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
        }

        img {
          vertical-align: middle;
          border-style: none;
        }
      }

      .subject-list-item___armnB {
        position: relative;
        height: 50px;
        line-height: 50px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #272727;
        cursor: pointer;
      }
    }

    .major-list___dQ4gN {
      flex: 1 1;
      background-color: #fcfcfc;

      .title___hhagm {
        box-sizing: content-box;
        background-color: #f5f5f5;
        padding-left: 20px;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 500;
        color: #272727;
        border-bottom: 1px solid #e4e4e4;
      }

      .major-list-wrap____DVl_ {
        padding: 0 30px 20px;

        .major-list-item___VibuW {
          display: flex;
          justify-content: space-between;
          height: 91px;
          border-bottom: 1px dashed #e4e4e4;

          .major-list-item-field___OcMjQ {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .major-list-item-name___ibG2t {
              font-size: 16px;
              font-weight: 500;
              color: #272727;
              margin-bottom: 10px;
              cursor: pointer;
            }

            .major-list-item-desc___ceRaB {
              display: flex;
              font-size: 14px;
              color: #646464;

              div {
                margin-right: 40px;
              }
            }

            .major-list-item-button___amzKV {
              width: 104px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              background: #ffffff;
              border-radius: 4px;
              border: 1px solid #187cff;
              font-size: 14px;
              font-weight: 500;
              color: #187cff;
              cursor: pointer;
            }

            a {
              text-decoration: none;
              outline: none;
              transition: color 0.3s;
            }
          }
        }
      }
    }
  }

  .header___WH_g7 {
    padding-bottom: 19px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;

    .header-title___eEYG1 {
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #272727;
      padding-left: 20px;
      border-left: 4px solid #187cff;
    }
  }

  .result___oxjNu {
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #e4e4e4;
    margin-bottom: 30px;

    .major-list___a3EDB {
      flex: 1 1;

      .title___xmzED {
        box-sizing: content-box;
        background-color: #f5f5f5;
        padding-left: 40px;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 500;
        color: #272727;
        border-bottom: 1px solid #e4e4e4;
      }

      .major-list-wrap___ZGTgc {
        padding: 0 40px;

        .major-list-item___ixB37 {
          display: flex;
          justify-content: space-between;
          height: 91px;
          border-bottom: 1px dashed #e4e4e4;
          align-items: center;

          .major-list-item-field___w0mcw {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .major-list-item-name___zYQN3 {
              font-size: 16px;
              font-weight: 500;
              color: #272727;
              margin-bottom: 10px;
              cursor: pointer;
              user-select: none;

              .major-list-item-key___hVkkP {
                color: #187cff;
              }
            }

            .major-list-item-desc___hztoS {
              display: flex;
              font-size: 14px;
              color: #646464;

              div {
                margin-right: 40px;
              }
            }
          }

          .major-list-item-button___NWOmH {
            width: 104px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #187cff;
            font-size: 14px;
            font-weight: 500;
            color: #187cff;
            cursor: pointer;
          }
        }
      }
    }
  }

  .header___WH_g7 {
    padding-bottom: 19px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;

    .header-title___eEYG1 {
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #272727;
      padding-left: 20px;
      border-left: 4px solid #187cff;
    }
  }

  .no-result___mB_JR {
    font-size: 16px;
    color: #272727;
    padding-left: 23px;
    margin-bottom: 30px;
  }
}
</style>
