import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken, removeToken } from "@/utils/auth.js";
// import { getToken } from "@/utils/auth.js";
import { Message, MessageBox } from "element-ui";

// 全局标志，避免重复跳转
let unauthorizedHandled = false;

// 创建第一个 axios 实例 (用于第一个基地址)
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_1, // 这里是第一个基地址
  timeout: 10000,
  headers: {},
});

// 创建第二个 axios 实例 (用于第二个基地址)
const request2 = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_2, // 这里是第二个基地址
  timeout: 10000,
  headers: {},
});

// 设置请求拦截器
function setupRequestInterceptor(requestInstance) {
  requestInstance.interceptors.request.use(
    (config) => {
      NProgress.start(); // 启动进度条
      addAuthorizationHeader(config); // 设置 token
      return config;
    },
    (error) => {
      NProgress.done(); // 失败时关闭进度条
      console.error("请求拦截器错误:", error);
      return Promise.reject(error);
    }
  );
}

// 设置响应拦截器
function setupResponseInterceptor(requestInstance) {
  requestInstance.interceptors.response.use(
    async (response) => {
      NProgress.done(); // 成功时关闭进度条

      // 检查是否为文件流（blob 类型）
      const contentType = response.headers["content-type"];
      if (response.config.responseType === "blob") {
        // 如果是 JSON（错误信息），需要解析错误
        if (contentType.includes("application/json")) {
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.onload = () => {
              try {
                const errorData = JSON.parse(reader.result); // 解析错误信息
                reject(errorData.message || "文件下载失败");
              } catch (err) {
                reject("文件下载失败，且无法解析错误信息！");
              }
            };
            reader.readAsText(response.data); // 读取 Blob 数据为文本
          });
        }
        // 如果是文件流，直接返回
        return response;
      }

      // 普通请求的处理逻辑
      if (response.data.success || response.data.code === 200|| response.data.status === 200) {
        return response;
      } else {
        // 处理业务失败的逻辑
        if (
          response.data.code === 401 ||
          (response.data.code === 400 && !unauthorizedHandled)
        ) {
          console.log("unauthorizedHandled", unauthorizedHandled);
          unauthorizedHandled = true; // 标记为已处理
          await handleUnauthorizedErrorWithQueue(response.data.message); // 执行 401 错误处理
          unauthorizedHandled = false; // 处理结束后重置标记
        }
        return Promise.reject(response.data.message || "业务失败");
      }
    },

    async (error) => {
      NProgress.done(); // 失败时关闭进度条
      const status = error.response?.status;

      // 如果是 401 错误
      if (status === 401 && !unauthorizedHandled) {
        unauthorizedHandled = true; // 标记为已处理
        await handleUnauthorizedErrorWithQueue(error.response?.data?.message); // 执行 401 错误处理
        unauthorizedHandled = false; // 处理结束后重置标记
      }

      // 服务器错误处理
      if (status === 500) {
        Message({
          message: "服务器出错，请稍后重试",
          type: "error",
          showClose: true,
        });
      }

      return Promise.reject(error);
    }
  );
}

// 设置 Authorization Header
function addAuthorizationHeader(config) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
}

// 处理 401 错误（未授权或登录过期）
let unauthorizedPromise = null; // 全局队列，用于同步 401 错误处理

async function handleUnauthorizedErrorWithQueue(message) {
  if (!unauthorizedPromise) {
    unauthorizedPromise = (async () => {
      console.log("响应拦截器 - 错误 401", message);

      // 提示错误信息
      Message({
        message: "您还未登录，或者登录已过期，请重新登录",
        type: "error",
        showClose: true,
      });

      removeToken(); // 清除 token

      // 弹框确认是否去登录
      try {
        await MessageBox.confirm(
          "登录已过期或未登录，是否跳转到登录页面？",
          "提示",
          {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        // 如果用户确认，则跳转到登录页面
        window.location.href =
          process.env.VUE_APP_SSO_URL +
          `?source=` +
          encodeURIComponent(window.location.origin);
      } catch (error) {
        // 如果用户取消，则无需跳转
        console.log("用户选择取消登录跳转");
      } finally {
        unauthorizedPromise = null; // 重置队列状态
      }
    })();
  }

  // 等待全局处理完成
  return unauthorizedPromise;
}

// 重置 unauthorizedHandled 标志
function resetUnauthorizedHandled() {
  unauthorizedHandled = false;
}

// 配置拦截器
setupRequestInterceptor(request);
setupResponseInterceptor(request);

setupRequestInterceptor(request2);
setupResponseInterceptor(request2);

// 导出两个不同的请求实例
export { request, request2, resetUnauthorizedHandled };
