import {request} from "@/utils/request";


// 获取院校库
export const GetUniversityPlanPro = (data) => {
  return request({
    method: "post",
    url: `/api/D_PlanMajorDesc/GetUniversityPlanPro`,
    data,
  });
};
