<template>
    <div class="box" style="max-width: 1200px;margin: 0 auto;">
        <div class="headline">
            <div class="school">
                <div class="schoolLogo">
                    <img :src="universityResult.Logo">
                </div>
                <div class="schoolInfo">
                    <h3 class="schoolName">
                        <em>{{ universityResult.Name }}</em>
                    </h3>
                    <div class="tags">
                        <span class="sp1">{{ universityResult.AscriptionName }}</span>
                        <span class="sp2" v-if="universityResult.Nhef">985</span>
                        <span class="sp3" v-if="universityResult.Sff">211</span>
                        <span class="sp4" v-if="universityResult.Syl">双一流</span>
                        <span class="sp5" v-if="universityResult.Qjjh">强基计划</span>
                    </div>
                    <div class="information">
                        <div class="URL">
                            <div class="ico">
                                <img src="./images/网址@2x.png">
                            </div>
                            <span v-if="universityResult.Web == '暂无'">暂无数据</span>
                            <span v-else>官网网址： <a :href="universityResult.Web">{{ universityResult.Web }}</a></span>
                        </div>
                        <div class="phone">
                            <div class="ico">
                                <img src="./images/3.1电话@2x.png">
                            </div>

                            <span v-if="universityResult.Phone == null">暂无数据</span>
                            <span v-else>{{ universityResult.Phone }}</span>
                        </div>
                        <div class="site">
                            <div class="ico">
                                <img src="./images/401位置备份@2x.png">
                            </div>
                            <span v-if="universityResult.Address == null">暂无数据</span>
                            <span>{{ universityResult.Address }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="function">
                    <div class="sc">
                        <div class="ico">
                            <img src="./images/收藏@2x.png">
                        </div>
                        <span>收藏</span>
                    </div>
                    <div class="db">
                        <div class="ico">
                            <img src="./images/加减组件 加号@2x.png">
                        </div>
                        <span>对比</span>
                    </div>
                </div> -->
            </div>
            <div class="schoolMessage">
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="universityResult.BuildDate" placement="top">
                            <div class="title">{{ universityResult.BuildDate == null ? '-' : universityResult.BuildDate }}
                            </div>
                        </el-tooltip>
                        <div>创建时间</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="universityResult.Nature == 0 ? '公办' : '民办'"
                            placement="top">
                            <div class="title">{{ universityResult.Nature == 0 ? '公办' : '民办' }}</div>
                        </el-tooltip>
                        <div>办学性质</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="universityResult.AreaName" placement="top">
                            <div class="title">{{ universityResult.AreaName }}</div>
                        </el-tooltip>
                        <div>院校所在地</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="universityResult.AscriptionName" placement="top">
                            <div class="title">{{ universityResult.AscriptionName }}</div>
                        </el-tooltip>
                        <div>隶属于</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="universityResult.SubjectLevel == 0 ? '本科' : '专科'"
                            placement="top">
                            <div class="title">{{ universityResult.SubjectLevel == 0 ? '本科' : '专科' }}</div>
                        </el-tooltip>
                        <div>学科层次</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="universityResult.UniversityType == 0 ? '综合' : universityResult.UniversityType == 1 ? '理工类'
                            : universityResult.UniversityType == 2 ? '医学类' : universityResult.UniversityType == 3 ? '军事类' : universityResult.UniversityType == 4 ? '语言类'
                                : universityResult.UniversityType == 5 ? '师范类' : universityResult.UniversityType == 6 ? '财经类' : universityResult.UniversityType == 7 ? '政法类'
                                    : universityResult.UniversityType == 8 ? '民族类' : universityResult.UniversityType == 9 ? '农林类' : universityResult.UniversityType == 10 ? '艺术类'
                                        : universityResult.UniversityType == 11 ? '体育类' : '其他'" placement="top">
                            <div class="title">{{ universityResult.UniversityType == 0 ? '综合' :
                                universityResult.UniversityType == 1 ? '理工类'
                                    : universityResult.UniversityType == 2 ? '医学类' : universityResult.UniversityType == 3 ?
                                        '军事类' : universityResult.UniversityType == 4 ? '语言类'
                                            : universityResult.UniversityType == 5 ? '师范类' : universityResult.UniversityType == 6 ?
                                                '财经类' : universityResult.UniversityType == 7 ? '政法类'
                                                    : universityResult.UniversityType == 8 ? '民族类' : universityResult.UniversityType == 9 ?
                                                        '农林类' : universityResult.UniversityType == 10 ? '艺术类'
                                                            : universityResult.UniversityType == 11 ? '体育类' : '其他' }}</div>
                        </el-tooltip>
                        <div>院校类型</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="String(universityResult.DoctorateCount)"
                            placement="top">
                            <div class="title">{{ universityResult.DoctorateCount == 0 ? '-' :
                                universityResult.DoctorateCount
                            }}</div>
                        </el-tooltip>
                        <div>博士点</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" content="-" placement="top">
                            <div class="title"> - </div>
                        </el-tooltip>
                        <div>重点学科</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" content="-" placement="top">
                            <div class="title">-</div>
                        </el-tooltip>
                        <div>学生人数</div>
                    </div>
                    <span class="ss"></span>
                </div>
                <div class="ta">
                    <div class="mesItem">
                        <el-tooltip class="item" effect="dark" :content="String(universityResult.AcademicianCount)"
                            placement="top">
                            <div class="title">{{ universityResult.AcademicianCount == 0 ? '-'
                                : universityResult.AcademicianCount }}</div>
                        </el-tooltip>
                        <div>院士人数</div>
                    </div>
                    <span class="ss"></span>
                </div>
            </div>
            <div class="tablist">
                <div :class="it.name == showHtml ? 'active tabItem' : 'tabItem'" v-for="(it, idx) in tabList" :key="idx"
                    @click="Active_AreaShow(it.name)">{{
                        it.name }}
                </div>
            </div>
        </div>
        <div class="Active_Area" v-if="showHtml == '学校总览'">
            <SchoolParticulars :Description="universityResult" />
        </div>
        <div class="Active_Area" v-if="showHtml == '招生简章'">
            <EnrollmentGuide :schoolId="schoolId" :name="universityResult.Name" :code="universityResult.UniversityCode"
                :college="universityResult.collegeCode" :isShow="show" />
        </div>
        <div class="Active_Area" v-if="showHtml == '招生计划'">
            <EnrollmentPlan :schoolId="schoolId" :name="universityResult.Name" :code="universityResult.UniversityCode" />
        </div>
        <div class="Active_Area" v-if="showHtml == '查扩缩招'">
            <Augment :schoolId="schoolId" :name="universityResult.Name" :code="universityResult.UniversityCode" />
        </div>
        <div class="Active_Area" v-if="showHtml == '录取分数线'">
            <EnrollmentMark :schoolId="schoolId" :name="universityResult.Name" :code="universityResult.UniversityCode" />
        </div>
        <div class="Active_Area" v-if="showHtml == '院校风光'">
            <CollegeStyle :Img="universityResult.Imglist" />
        </div>
    </div>
</template>

<script>
import SchoolParticulars from "./components/schoolParticulars";
import EnrollmentGuide from "./components/EnrollmentGuide";
import EnrollmentPlan from "./components/EnrollmentPlan";
import CollegeStyle from "./components/CollegeStyle";
import EnrollmentMark from "./components/EnrollmentMark";
import Augment from "./components/Augment";
import { GetUniversityDetails } from "@/api/schoolDetails";
export default {
    name: 'VoluntaryReportingIndex',
    components: {
        SchoolParticulars,
        EnrollmentGuide,
        EnrollmentPlan,
        CollegeStyle,
        EnrollmentMark,
        Augment
    },
    data() {
        return {
            show: true,
            schoolId: '',
            graduateModels: {},
            relatedMajors: [],
            relatespMajors: [],
            universityResult: {},
            showHtml: '学校总览',
            tabList: [
                {
                    name: '学校总览'
                },
                {
                    name: '招生简章'
                },
                {
                    name: '招生计划'
                },
                {
                    name: '查扩缩招'
                },
                {
                    name: '录取分数线'
                },
                {
                    name: '院校风光'
                },
            ],

        };
    },

    mounted() {
        //其他页面学校id
        this.schoolId = this.$route.query.Id
        // 其他页面高亮
        if (this.$route.query.showName !== undefined) {
            this.showHtml = this.$route.query.showName
        }
        //获取学校详情
        this.getList()
    },

    methods: {
        Active_AreaShow(name) {
            this.showHtml = name
            this.show = !this.show
        },
        async getList() {
            const res = await GetUniversityDetails({
                Id: this.schoolId
            })
            this.graduateModels = res.data.response.graduateModels
            this.relatedMajors = res.data.response.relatedMajors
            this.relatespMajors = res.data.response.relatespMajors
            this.universityResult = res.data.response.universityResult
        }
    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.headline {
    margin-top: 24px;


    .school {
        display: flex;
        position: relative;

        .schoolLogo {
            width: 100px;
            height: 100px;
            margin-right: 36px;
        }

        .schoolInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .schoolName {
                font-size: 32px;
            }

            .tags {
                display: flex;
                align-items: center;

                .sp1 {
                    background: #CCD9FF;
                }

                .sp2 {
                    background: #F3E2C2;
                }

                .sp3 {
                    background: #D4F0DD;
                }

                .sp4 {
                    background: #F8CFCF;
                }

                .sp5 {
                    background: #EDCFF8
                }

                span {
                    padding: 0 13px;
                    height: 20px;
                    background: #CCD9FF;
                    border-radius: 5px;
                    font-size: 14px;
                    color: #666666;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                }
            }

            .information {
                display: flex;
                align-items: center;

                .URL,
                .phone,
                .site {
                    display: flex;
                    align-items: center;
                    margin-right: 38px;
                    color: #999999;
                    font-size: 14px;

                    .ico {
                        width: 12px;
                        margin-right: 5px;
                    }
                }

            }

        }

        .function {
            position: absolute;
            right: 34px;
            bottom: 13px;
            display: flex;

            .sc,
            .db {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 68px;
                height: 30px;
                border-radius: 5px;
                border: 1px solid #979797;
                margin-left: 29px;
                color: #333333;
                font-size: 14px;

                .ico {
                    width: 14px;
                    height: 13px;
                    margin-right: 8px;
                }
            }
        }
    }

    .schoolMessage {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;

        .ta {
            display: flex;
            align-items: center;
            justify-content: center;

            .mesItem {
                min-width: 100px;
                height: 56px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                div {
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-size: 14px;
                    color: #999999;
                }

                .title {
                    text-align: center;
                    width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 20px;
                    font-weight: 600;
                    color: #333;
                }
            }

            .ss {
                width: 1px;
                height: 40px;
                background: #D8D8D8;
            }
        }

        .ta:nth-last-child(1) .ss {
            opacity: 0;
        }
    }

    .tablist {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        border-bottom: #187cff solid 1px;

        .tabItem {
            width: 142px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            color: #333333;
            border-radius: 10px 10px 0px 0px;
            cursor: pointer;
        }

        .active {
            background: #187cff;
            color: #fff;
        }
    }


}
</style>