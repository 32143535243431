var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",staticStyle:{"max-width":"1200px","margin":"0 auto"}},[_c('div',{staticClass:"my-zytable-page-title page-title-bl4 mt60 mb15"},[_c('span',{staticClass:"pull-left"},[_vm._v(_vm._s(_vm.phone)+" 的志愿表")])]),_c('div',{staticClass:"tableList"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.list,"align":"center","header-cell-style":{
        background: '#eaeaea',
        color: '#606266',
        fontWeight: 400,
      }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","align":"center","width":"50"}}),_c('el-table-column',{attrs:{"prop":"tableName","align":"center","width":"150"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("志愿表")])])])],2),_c('el-table-column',{attrs:{"label":"批次","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(scope.row.batchName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"score","align":"center"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("成绩")])])])],2),_c('el-table-column',{attrs:{"prop":"subjectClaim","align":"center"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("科目")])])])],2),_c('el-table-column',{attrs:{"label":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(scope.row.type))])])]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","align":"center","width":"180"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("生成时间")])])])],2),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goTY(scope.row.vId, scope.row.personlocationCode)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.getExport(
                scope.row.vId,
                scope.row.personlocationCode,
                scope.row.customerName
              )}}},[_vm._v("下载")])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }