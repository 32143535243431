<template>
  <div class="box" style="max-width: 1200px; margin: 0 auto; padding-top: 10px">
    <el-select
      style="width: 100px"
      size="small"
      v-model="value"
      placeholder="请选择"
      @change="provinceChange($event)"
    >
      <el-option
        v-for="item in listInit"
        :key="item.ProvinceName"
        :label="item.ProvinceName"
        :value="item.Code"
      >
      </el-option>
    </el-select>
    <!--  -->
    <div class="uzy-tzy-tip-instruction">
      <h6>重要提示：</h6>
      <div class="dataDescription">
        1、2024批次线/省控线已更新<br />2、在正式填报时，以教育考试院公布的批次线/省控线为准。
      </div>
    </div>
    <!--  -->
    <div class="page-content-list" v-if="list !== null">
      <el-col :span="24" class="table-row">
        <div class="table-fixed">
          <div class="pcl-title">2024年批次线</div>
          <table border-spacing="0" class="pcl-table">
            <thead>
              <th width="333">批次/段</th>
              <th width="168">科目</th>
              <th width="168">控制分数线</th>
              <el-tooltip
                class="item"
                effect="dark"
                content="【压线分区间】因省控线通常按该批次计划总数的120%划线，因此会导致该批次近20%考生存在滑档或退档风险。在此我们通过逆向计算得到压线分区间，建议在此分数区间的考生选择多批次填报，以降低退/滑档风险。"
                placement="top"
              >
                <th>压线分区间</th>
              </el-tooltip>
            </thead>
          </table>
        </div>
        <table border-spacing="0" class="pcl-table">
          <tbody>
            <tr v-for="item in listFive" :key="item.Id">
              <td width="333" align="center" class="batch">{{ item.Name }}</td>
              <td width="168" align="center">{{ item.TypeName }}</td>
              <td width="168" align="center">{{ item.Score }}</td>
              <td align="center">-</td>
            </tr>
            <!---->
          </tbody>
        </table>
      </el-col>
      <el-col :span="24" class="table-row">
        <div class="table-fixed">
          <div class="pcl-title">2023年批次线</div>
          <table border-spacing="0" class="pcl-table">
            <thead>
              <th width="333">批次/段</th>
              <th width="168">科目</th>
              <th width="168">控制分数线</th>
              <el-tooltip
                class="item"
                effect="dark"
                content="【压线分区间】因省控线通常按该批次计划总数的120%划线，因此会导致该批次近20%考生存在滑档或退档风险。在此我们通过逆向计算得到压线分区间，建议在此分数区间的考生选择多批次填报，以降低退/滑档风险。"
                placement="top"
              >
                <th>压线分区间</th>
              </el-tooltip>
            </thead>
          </table>
        </div>
        <table border-spacing="0" class="pcl-table">
          <tbody>
            <tr v-for="item in listFour" :key="item.Id">
              <td width="333" align="center" class="batch">{{ item.Name }}</td>
              <td width="168" align="center">{{ item.TypeName }}</td>
              <td width="168" align="center">{{ item.Score }}</td>
              <td align="center">-</td>
            </tr>
            <!---->
          </tbody>
        </table>
      </el-col>
      <el-col :span="24" class="table-row">
        <div class="table-fixed">
          <div class="pcl-title">2022年批次线</div>
          <table border-spacing="0" class="pcl-table">
            <thead>
              <th width="333">批次/段</th>
              <th width="168">科目</th>
              <th width="168">控制分数线</th>
              <el-tooltip
                class="item"
                effect="dark"
                content="【压线分区间】因省控线通常按该批次计划总数的120%划线，因此会导致该批次近20%考生存在滑档或退档风险。在此我们通过逆向计算得到压线分区间，建议在此分数区间的考生选择多批次填报，以降低退/滑档风险。"
                placement="top"
              >
                <th>压线分区间</th>
              </el-tooltip>
            </thead>
          </table>
        </div>
        <table border-spacing="0" class="pcl-table">
          <tbody>
            <tr v-for="item in listTree" :key="item.Id">
              <td width="333" align="center" class="batch">{{ item.Name }}</td>
              <td width="168" align="center">{{ item.TypeName }}</td>
              <td width="168" align="center">{{ item.Score }}</td>
              <td align="center">-</td>
            </tr>
            <!---->
          </tbody>
        </table>
      </el-col>
      <el-col :span="24" class="table-row">
        <div class="table-fixed">
          <div class="pcl-title">2021年批次线</div>
          <table border-spacing="0" class="pcl-table">
            <thead>
              <th width="333">批次/段</th>
              <th width="168">科目</th>
              <th width="168">控制分数线</th>
              <el-tooltip
                class="item"
                effect="dark"
                content="【压线分区间】因省控线通常按该批次计划总数的120%划线，因此会导致该批次近20%考生存在滑档或退档风险。在此我们通过逆向计算得到压线分区间，建议在此分数区间的考生选择多批次填报，以降低退/滑档风险。"
                placement="top"
              >
                <th>压线分区间</th>
              </el-tooltip>
            </thead>
          </table>
        </div>
        <table border-spacing="0" class="pcl-table">
          <tbody>
            <tr v-for="item in listTwo" :key="item.Id">
              <td width="333" align="center" class="batch">{{ item.Name }}</td>
              <td width="168" align="center">{{ item.TypeName }}</td>
              <td width="168" align="center">{{ item.Score }}</td>
              <td align="center">-</td>
            </tr>
            <!---->
          </tbody>
        </table>
      </el-col>
      <el-col :span="24" class="table-row">
        <div class="table-fixed">
          <div class="pcl-title">2020年批次线</div>
          <table border-spacing="0" class="pcl-table">
            <thead>
              <th width="333">批次/段</th>
              <th width="168">科目</th>
              <th width="168">控制分数线</th>
              <el-tooltip
                class="item"
                effect="dark"
                content="【压线分区间】因省控线通常按该批次计划总数的120%划线，因此会导致该批次近20%考生存在滑档或退档风险。在此我们通过逆向计算得到压线分区间，建议在此分数区间的考生选择多批次填报，以降低退/滑档风险。"
                placement="top"
              >
                <th>压线分区间</th>
              </el-tooltip>
            </thead>
          </table>
        </div>
        <table border-spacing="0" class="pcl-table">
          <tbody>
            <tr v-for="item in listOne" :key="item.Id">
              <td width="333" align="center" class="batch">{{ item.Name }}</td>
              <td width="168" align="center">{{ item.TypeName }}</td>
              <td width="168" align="center">{{ item.Score }}</td>
              <td align="center">-</td>
            </tr>
            <!---->
          </tbody>
        </table>
      </el-col>
    </div>
    <el-empty v-else description="暂无数据"></el-empty>
  </div>
</template>

<script>
import { GetSeVolunteerInitialization } from "@/api/home";
import { GetBatchByYearArea } from "@/api/diplomaMill";
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      listInit: [],
      list: [],
      listOne: [],
      listTwo: [],
      listTree: [],
      listFour: [],
      listFive: [],
      value: "",
      noList: false,
    };
  },

  mounted() {
    let studentInfo = JSON.parse(sessionStorage.getItem("setStudentInfo"));
    this.value = studentInfo.userExtend.provinceCode;
    this.getSeVolunteerList();
    this.getBatchByYearAreaList();
  },

  methods: {
    provinceChange() {
      this.getBatchByYearAreaList();
    },
    // 获取省份
    async getSeVolunteerList() {
      const res = await GetSeVolunteerInitialization();
      this.listInit = res.data.response;
    },
    // 获取批次线
    async getBatchByYearAreaList() {
      const res = await GetBatchByYearArea({
        Year: "",
        AreaName: "",
        ProvinceCode: this.value,
      });
      this.listOne = [];
      this.listTwo = [];
      this.listTree = [];
      this.listFour = [];
      this.listFive = [];
      res.data.response.forEach((it) => {
        if (it.Year == 2020) {
          this.listOne.push(it);
        } else if (it.Year == 2021) {
          this.listTwo.push(it);
        } else if (it.Year == 2022) {
          this.listTree.push(it);
        } else if (it.Year == 2023) {
          this.listFour.push(it);
        } else if (it.Year == 2024) {
          this.listFive.push(it);
        }
      });
      this.list = res.data.response;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: Microsoft YaHei, SimSun, Arial, sans-serif;
}

.uzy-tzy-tip-instruction {
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  line-height: 20px;
  width: 100%;
  border: 1px dashed #f66;
  border-radius: 3px;
  background: #fff9f9;
  padding: 10px;
}

.page-content-list .table-row {
  margin-bottom: 13px;

  .table-fixed {
    .pcl-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #000;
    }

    .pcl-table {
      width: 100%;
      border-top: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
      border-bottom: none;
      border-spacing: 0 !important;
      background-color: #fff;
    }

    thead {
      height: 42px;
      background: #f6f6f6;

      th {
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        padding: 14px 20px;
        color: #000;
        font-size: 14px;
      }
    }
  }

  .pcl-table {
    width: 100%;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-bottom: none;
    border-spacing: 0 !important;
    background-color: #fff;

    tbody tr {
      height: 48px;

      td {
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        padding: 13px 20px;
        font-size: 14px;
      }

      .batch {
        font-weight: 600;
        color: #333;
      }
    }

    .remark-row {
      height: 42px;

      td {
        padding: 10px 54px;
        font-size: 12px;
        padding-left: 74px;

        .text-666 {
          color: #666 !important;
        }
      }
    }
  }
}
</style>
