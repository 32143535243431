export const downloadFile = (
  response,
  xlsxName,
  defaultFileName = xlsxName + `的志愿表` + ".xlsx"
) => {
  // 创建 Blob 对象
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  // 解析文件名（从 Content-Disposition 中提取）
  const contentDisposition = response.headers["content-disposition"];
  const fileName = contentDisposition
    ? decodeURIComponent(
        contentDisposition.match(/filename="(.+)"/)?.[1] || defaultFileName
      )
    : defaultFileName;

  // 创建链接并触发下载
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // 移除链接
  document.body.removeChild(link);
};
