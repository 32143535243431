<template>
  <div class="boxs">
    <div class="titles">我的订单</div>
    <div class="content">
      <!-- <div class="cuts">
        <div
          :class="it.name == name ? 'cutActive' : 'cut'"
          v-for="(it, idx) in cutList"
          :key="idx"
          @click="cut(it.name, idx)"
        >
          {{ it.name }}
        </div>
      </div> -->
      <div class="table">
        <el-table
          :data="list"
          style="width: 100%"
          stripe
          :header-cell-style="{ background: '#EFF2FF', color: '#A2AAB4' }"
        >
          <el-table-column prop="phone" label="用户名称" align="center">
          </el-table-column>
          <el-table-column prop="cartName" label="服务项目" align="center">
          </el-table-column>
          <el-table-column prop="amount" label="支付金额" align="center">
          </el-table-column>
          <el-table-column
            prop="createTime"
            width="180"
            label="付款时间"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="fy">
          <!-- 分页 -->
          <el-pagination
            layout=" sizes,prev, pager, next, jumper"
            :page-size="10"
            :page-sizes="[5]"
            :total="total"
            @current-change="hCurrentChange"
            @size-change="hSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userOrderList } from "@/api/zybEditor";
export default {
  name: "VoluntaryReportingMyOrder",

  data() {
    return {
      total: 0,
      name: "全部",
      obj: {
        Status: -1,
        PageIndex: 1,
        PageSize: 5,
      },
      list: [],
      userInfo: {},
      cutList: [
        {
          name: "全部",
        },
        {
          name: "已支付",
        },
        {
          name: "未支付",
        },
      ],
    };
  },

  mounted() {
    this.userInfo = this.$store.state.user.studentInfo || "";
    this.getList();
  },

  methods: {
    // 会自动接收当前点击的页码
    hCurrentChange(curPage) {
      // 1. 更新页码
      this.obj.PageIndex = curPage;
      // 2. 重发请求
      this.getList();
    },
    // 每页几条
    hSizeChange(curSize) {
      // 1. 更新每页的条数
      this.obj.PageSize = curSize;

      // 2. 重发请求
      this.getList();
    },
    // cut(name, idx) {
    //   this.obj.PageIndex = 1;
    //   this.name = name;
    //   if (idx == 0) {
    //     this.obj.Status = -1;
    //   }
    //   if (idx == 1) {
    //     this.obj.Status = 1;
    //   }
    //   if (idx == 2) {
    //     this.obj.Status = 0;
    //   }
    //   this.list = [];
    //   this.getList();
    // },
    // 获取个人信息
    async getList() {
      if (this.obj.Status == -1) {
        delete this.obj.Status;
      }
      const res = await userOrderList({
        Phone: this.userInfo.mobile,
      });
      this.list = [];
      this.total = 0;
      this.list = res.data.result;
      this.total = this.list.length;
    },
  },
};
</script>

<style lang="less" scoped>
.fy {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* 设置背景颜色 */
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
}

.boxs {
  background: #fff;
  position: relative;
  height: 100%;
}

.titles {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 73px;
}

.content {
  .cuts {
    display: flex;
    margin-bottom: 27px;

    .cut {
      cursor: pointer;
      position: relative;
      font-size: 20px;
      color: #999999;
      transform-style: preserve-3d;
      margin-right: 65px;
    }

    .cutActive {
      cursor: pointer;
      position: relative;
      font-size: 20px;
      color: #187cff;
      transform-style: preserve-3d;
      margin-right: 65px;
    }

    .cutActive::before {
      content: "";
      position: absolute;
      bottom: -10px;
      z-index: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 3px;
      background: #187cff;
      border-radius: 30px;
    }
  }
}
</style>
