<template>
  <div id="app">
    <div
      class="SwiperBox"
      @mouseenter.stop="MouseFun('移入')"
      @mouseleave.stop="MouseFun('移出')"
    >
      <!-- 图片容器 -->
      <div
        class="imgWrapper"
        :style="imgWrapperStyle"
        @transitionend="handleTransitionEnd"
      >
        <img
          class="imgCss"
          :src="item.imgUrl"
          v-for="(item, index) in imgListWithClone"
          :key="index"
        />
      </div>
      <!-- 左箭头按钮 -->
      <div class="leftBtn" @click="throttle(PrevFun)">
        <i class="el-icon-arrow-left"></i>
      </div>
      <!-- 右箭头按钮 -->
      <div class="rightBtn" @click="throttle(NextFun)">
        <i class="el-icon-arrow-right"></i>
      </div>
      <!-- 下方指示点容器 -->
      <div class="instBox">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          @mouseover="jumpToImg(index)"
          :class="['inst', ShowImg === index ? 'instActv' : '']"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OCRrecognitionIndex",
  data() {
    return {
      imgList: [
        {
          imgUrl: require("../../assets/images/banner1@2x.png"),
        },
        {
          imgUrl: require("../../assets/images/banner2@2x.png"),
        },
      ],
      ShowImg: 0, // 当前显示的图片索引
      transitioning: false, // 是否正在过渡
      start: null, // 定时器
    };
  },
  computed: {
    imgListWithClone() {
      // 在列表前后添加克隆的图片
      return [
        this.imgList[this.imgList.length - 1],
        ...this.imgList,
        this.imgList[0],
      ];
    },
    imgWrapperStyle() {
      return {
        transform: `translateX(-${(this.ShowImg + 1) * 100}%)`,
        transition: this.transitioning ? "transform 0.5s ease-in-out" : "none",
      };
    },
  },
  mounted() {
    this.setTimeoFun();
  },
  methods: {
    MouseFun(type) {
      // 鼠标移入时清除定时器，移出时重新启动定时器
      type === "移入" ? clearInterval(this.start) : this.setTimeoFun();
    },
    setTimeoFun() {
      // 自动轮播定时器，间隔4秒切换
      clearInterval(this.start);
      this.start = setInterval(() => {
        if (!this.transitioning) {
          this.transitioning = true;
          this.NextFun();
        }
      }, 3000);
    },
    throttle(fun) {
      // 节流函数，避免按钮多次快速点击
      if (!this.transitioning) {
        this.transitioning = true;
        fun();
      }
    },
    PrevFun() {
      // 向前切换图片
      this.ShowImg--;
      if (this.ShowImg < 0) {
        // 当切换到前面的克隆图时，调整索引并去掉过渡效果
        setTimeout(() => {
          this.transitioning = false;
          this.ShowImg = this.imgList.length - 1;
        }, 500);
      }
    },
    NextFun() {
      // 向后切换图片
      this.ShowImg++;
      if (this.ShowImg >= this.imgList.length) {
        // 当切换到后面的克隆图时，调整索引并去掉过渡效果
        setTimeout(() => {
          this.transitioning = false;
          this.ShowImg = 0;
        }, 500);
      }
    },
    jumpToImg(index) {
      // 跳转到指定图片索引
      if (!this.transitioning) {
        this.transitioning = true;
        this.ShowImg = index;
      }
    },
    handleTransitionEnd() {
      // 处理过渡结束后的逻辑
      if (this.ShowImg < 0) {
        this.transitioning = false;
        this.ShowImg = this.imgList.length - 1;
      } else if (this.ShowImg >= this.imgList.length) {
        this.transitioning = false;
        this.ShowImg = 0;
      } else {
        this.transitioning = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.SwiperBox {
  position: relative;
  width: 100%;
  height: 440px;
  overflow: hidden;
}

.imgWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.imgCss {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.leftBtn,
.rightBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(109, 109, 109, 0.445);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
}

.SwiperBox:hover .leftBtn,
.SwiperBox:hover .rightBtn {
  opacity: 1 !important;
}

.leftBtn {
  left: 5%;
}

.rightBtn {
  right: 5%;
}

.instBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  display: flex;
}

.inst {
  display: block;
  opacity: 0.48;
  width: 30px;
  height: 2px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 5px;
}

.inst:last-child {
  margin-right: 0;
}

.instActv {
  opacity: 1;
}

#app {
  width: 100%;
}
</style>
