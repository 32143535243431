<template>
  <div class="box" style="max-width: 1200px; margin: 0 auto">
    <div class="my-zytable-page-title page-title-bl4 mt60 mb15">
      <span class="pull-left">我的志愿表</span>
      <!-- <span class="pull-right f14 my-zytable-filter"><b class="text-primary active">个人</b>|<b>专家</b></span> -->
    </div>
    <div class="tableList">
      <el-table
        border
        :data="list"
        align="center"
        style="width: 100%"
        :header-cell-style="{
          background: '#eaeaea',
          color: '#606266',
          fontWeight: 400,
        }"
      >
        <el-table-column type="index" label="序号" align="center" width="50" />
        <el-table-column prop="tableName" align="center" width="150">
          <template slot="header">
            <div style="text-align: center">
              <div>志愿表</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="批次" align="center">
          <template slot-scope="scope">
            <div style="text-align: center">
              <div>{{ scope.row.batchName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="score" align="center">
          <template slot="header">
            <div style="text-align: center">
              <div>成绩</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subjectClaim" align="center">
          <template slot="header">
            <div style="text-align: center">
              <div>科目</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <div style="text-align: center">
              <div>{{ scope.row.type }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="customerName" align="center">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>状态</div>
                        </div>
                    </template>
                </el-table-column> -->
        <el-table-column prop="createTime" align="center" width="180">
          <template slot="header">
            <div style="text-align: center">
              <div>最后编辑时间</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="tj-button"
              @click="goTY(scope.row.vId)"
              >查看</el-button
            >
            <el-button
              type="text"
              class="yj-button"
              v-if="studentInfo.customerType == 0 && scope.row.isPush == true"
              >已交</el-button
            >
            <el-button
              type="text"
              class="tj-button"
              @click="goTJ(scope.row.vId)"
              v-if="studentInfo.customerType == 0 && scope.row.isPush !== true"
              >提交</el-button
            >
          </template>
        </el-table-column>
        <!-- 下来框 -->
      </el-table>
      <div style="height: 60px; margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          layout="total, sizes,prev, pager, next, jumper"
          :page-size="obj.PageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="hCurrentChange"
          @size-change="hSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GetVolunteer, Delete, pushStatus } from "@/api/zybEditor";
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      obj: {
        page: 1,
        pageSize: 10,
      },
      list: [],
      studentInfo: "",
      fetchDate: {
        size: 10,
      },
      total: 2,
    };
  },

  mounted() {
    this.studentInfo = this.$store.state.user.studentInfo;
    this.getList();
  },

  methods: {
    // 删除
    async getDelete(id) {
      console.log(id);
      const res = await Delete(id);
      if (res.data.msg == "删除成功") {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList();
      }
    },
    // 获取列表
    async getList() {
      const res = await GetVolunteer({
        ...this.obj,
      });
      this.list = res.data.result.items;
      this.total = this.list.length;
    },

    hSizeChange(curSize) {
      this.obj.pageSize = curSize;
      this.obj.page = 1;
      this.getList();
    },
    hCurrentChange(curSize) {
      // 1. 更新每页的条数
      this.obj.page = curSize;
      this.getList();
    },
    goTY(VId) {
      this.$router.push({ path: "/Home/zybEditorTwo", query: { VId } });
    },
    async goTJ(id) {
      this.$confirm("订单未支付将无法提交给专家预审，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          await pushStatus({
            id,
          });
          this.getList();
        })
        .catch(() => {
          // 取消操作
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  float: right;
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  margin-top: 30px !important;
}

.page-title-bl4 {
  border-color: #2577e3 !important;
}

.page-title-bl4 {
  border-left: 4px solid;
  color: #333;
  font-size: 20px;
  line-height: 22px;
  height: 22px;
  padding-left: 10px;
  font-weight: 500;
  margin-bottom: 15px !important;
  margin-top: 30px !important;

  .pull-left {
    float: left !important;
  }

  .pull-right {
    float: right !important;
    font-size: 14px !important;
    cursor: pointer;

    b {
      font-weight: bold;
    }

    .text-primary {
      color: #2577e3 !important;
    }
  }
}
.tj-button {
  color: #4549ff;
}
.yj-button {
  color: #919191;
}
</style>
