<template>
    <div class="box">
        <div class="content">
            <div class="titleImg">
                <img src="./images/编组 2@2x.png" alt="">
            </div>
            <div class="search">
                <el-input v-model="serveValue" @keyup.enter.native="serveSchool()" placeholder="请输入内容"></el-input>
                <div @click="serveSchool()">
                    <button style="cursor: pointer;">识别</button>
                </div>
            </div>
            <div class="listTitle">虚假大学名单（<span>393</span>所）</div>
            <div class="explain">数据来源@人民日报，2023已更新《报志愿注意！393所虚假大学名单》</div>
            <div class="list">
                <el-table stripe border :data="tableData" align="center" style="width: 100%"
                    :header-cell-style="{ background: '#CFDBFF', color: '#333333' }">
                    <el-table-column label="办学地点" align="center">
                        <template slot-scope="scope">
                            <div style="color: rgb(24, 144, 255)">
                                {{ scope.row.Location == null ? '-' : scope.row.Location }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="UniversityName" align="center">
                        <template slot="header">
                            <div style="text-align: center">
                                <div>大学名称</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { GetFackUniversitys, GetFack_Universitys } from "@/api/diplomaMill";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            serveValue: '',
            tableData: []
        };
    },

    mounted() {
        this.getList()
    },

    methods: {
        async getList() {
            const res = await GetFackUniversitys()
            this.tableData = res.data.response
            this.status = res.data.status
        },
        async serveSchool() {
            if (this.serveValue == '') {
                this.getList()
            } else {
                const res = await GetFack_Universitys({
                    KeyWord: this.serveValue
                })
                this.tableData = res.data.response
                this.status = res.data.status
            }

        }
    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    background: #F5F4F8;
    display: flex;
    justify-content: center;
}

.content {
    width: 1343px;
    background: #FFFFFF;
    margin-top: 30px;
    padding: 27px 31px;
    box-sizing: border-box;
    min-height: 1000px;

    .titleImg {
        width: 1277px;
        height: 217px;
    }

    .search {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        ::v-deep .el-input__inner {
            width: 780px;
            height: 70px;
            background: #F1F1F1;
            border-radius: 37px;
            text-align: center;
            font-size: 24px;
            color: #999999;
        }

        button {
            width: 470px;
            height: 70px;
            background: #187cff;
            border-radius: 37px;
            text-align: center;
            line-height: 70px;
            color: #FFFFFF;
            font-size: 24px;
            border: none;
        }
    }

    .listTitle {
        margin-top: 50px;
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        margin-bottom: 10px;

        span {
            color: #466EE7;
        }
    }

    .explain {
        color: #999999;
    }

    .list {
        margin-top: 50px;

        ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background: #F3F6FF !important;
        }
    }
}
</style>