<template>
  <div class="box">
    <div class="content">
      <div class="left">
        <div class="item">
          <div class="title">简介</div>
          <div class="itName">媒体报道</div>
          <div class="itName">关于我们</div>
          <div class="itName">联系我们</div>
        </div>
        <div class="item">
          <div class="title">新手帮助</div>
          <div class="itName">院校优先填报</div>
          <div class="itName">六纬大数据</div>
          <div class="itName">六纬大数据</div>
        </div>
        <div class="item">
          <div class="title">官方声明</div>
          <div class="itName">意见反馈</div>
          <div class="itName">用户协议</div>
        </div>
        <div class="item">
          <div class="title">热门链接</div>
          <div class="itName">六纬生涯</div>
          <div class="itName">六纬留学</div>
          <div class="itName">六纬考研</div>
        </div>
      </div>
      <div class="right">
        <div class="l">
          <img src="./images/codes.png" alt="">
        </div>
        <div class="r">
          <div class="title">400-621-1003</div>
          <div class="itCode">
            <div>扫描此二维码进行咨询</div>
            <div>咨询时间：周一至周日 9:00-18:00</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {};
  },

  mounted() { },

  methods: {},
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  width: 100%;
  height: 237px;
  box-sizing: border-box;
  background: #272c2f !important;

  .content {
    width: 1164px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #c6c6c6;
    padding-top: 55px;

    .left {
      display: flex;
      align-items: baseline;

      .item {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        margin-right: 64px;

        .title {
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: bold;
        }

        .itName {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }

    .right {
      display: flex;

      .l {
        width: 92px;
        height: 92px;
      }

      .r {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 32px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>