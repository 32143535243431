import {
  setToken,
  removeToken,
  removeToken1,
  removeId,
  getToken,
  getId,
  setId,
  getServiceToken,
  setServiceToken,
} from "@/utils/auth.js";
// import { resetRouter } from "@/router";
// import { reqLogin } from "@/api/user";

export default {
  namespaced: true,
  state: {
    tenantId: JSON.parse(sessionStorage.getItem("setTenantId")) || "",
    studentInfo: JSON.parse(sessionStorage.getItem("setStudentInfo")) || "",
    // 优先从本地取token
    token: getToken() || null,
    serviceToken: getServiceToken() || null,
    Id: getId() || null,
    otherUrl: "",
    ProvinceName: JSON.parse(sessionStorage.getItem("setProvinceName")) || "",
    Policy: Number(sessionStorage.getItem("setPolicy")) || "",
    provinceShow: false,
    provinceObj: JSON.parse(sessionStorage.getItem("setProvinceObj")) || {},
    thirdToken: "",
  },
  mutations: {
    // 用户省份
    setProvinceObj(state, newProvinceObj) {
      state.provinceObj = newProvinceObj;
      sessionStorage.setItem("setProvinceObj", JSON.stringify(newProvinceObj));
    },
    // 用户省份显示
    setProvinceShow(state, newProvinceShow) {
      state.provinceShow = newProvinceShow;
    },
    // 选择的省份
    setProvinceName(state, ProvinceName) {
      state.ProvinceName = ProvinceName;
      sessionStorage.setItem("setProvinceName", JSON.stringify(ProvinceName));
      console.log("state.provinceShow", state.ProvinceName);
    },
    // 用户政策
    setPolicy(state, Policy) {
      state.Policy = Policy;
      sessionStorage.setItem("setPolicy", JSON.stringify(Policy));
    },
    // 用户id
    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
      sessionStorage.setItem("setTenantId", JSON.stringify(tenantId));
    },
    // 用户个人信息
    setStudentInfo(state, studentInfo) {
      state.studentInfo = studentInfo;
    },
    // 删除用户id
    removeProvinceName(state) {
      state.ProvinceName = "";
    },
    // 删除用户id
    removeStudentInfo(state) {
      state.studentInfo = "";
    },
    // 删除用户id
    removeTenantId(state) {
      state.tenantId = "";
    },
    // 保存serviceToken
    setSerToken(state, newServiceToken) {
      state.serviceToken = newServiceToken;
      // 本地保存一下
      setServiceToken(newServiceToken);
    },
    // 保存token
    setTokens(state, newToken) {
      state.token = newToken;
      // 本地保存一下
      setToken(newToken);
    },
    setthridtoken(state, token) {
      state.thirdToken = token;
    },
    // 保存id
    setIds(state, newId) {
      state.Id = newId;
      // 本地保存一下
      setId(newId);
    },
    // 保存跳转地址
    setOtherUrl(state, newOtherUrl) {
      state.otherUrl = newOtherUrl;
    },
    // 删除token
    removeTokens(state) {
      state.token = null; // 删除vuex的token
      state.serviceToken = null; // 删除vuex的token
      removeToken();
      removeToken1();
      removeId();
    },
  },
  actions: {
    logout(context) {
      context.commit("removeTokens");
      context.commit("removeProvinceName");
      localStorage.removeItem("setProvinceName"); // 如果存储在 localStorage 中
      sessionStorage.removeItem("setProvinceName"); // 如果存储在 sessionStorage 中
      // 重置路由
      // resetRouter();
    },
    removeStudentInfo(context) {
      context.commit("removeStudentInfo");
      localStorage.removeItem("setStudentInfo"); // 如果存储在 localStorage 中
      sessionStorage.removeItem("setStudentInfo"); // 如果存储在 sessionStorage 中

      // 重置路由
      // resetRouter();
    },
    // 登录
    // async userLogin(context, data) {
    //   const res = await reqLogin(data);
    //   context.commit("setOtherUrl", res.otherUrl);
    //   context.commit("setTokens", res.data);
    //   context.commit("setTenantId", res.tenantId);
    //   context.commit("setStudentInfo", res.studentInfo);
    // },
  },
};
