<template>
  <div class="boxs">
    <div class="titles">我的订单</div>
    <div class="content">
      <div class="table">
        <el-table
          :data="list"
          style="width: 100%"
          stripe
          :header-cell-style="{ background: '#EFF2FF', color: '#A2AAB4' }"
        >
          <el-table-column prop="phone" label="用户名称" align="center">
          </el-table-column>
          <el-table-column prop="cartName" label="服务项目" align="center">
          </el-table-column>
          <el-table-column prop="amount" label="支付金额" align="center">
          </el-table-column>
          <el-table-column
            prop="createTime"
            width="180"
            label="付款时间"
            align="center"
          >
          </el-table-column>
          <el-table-column label="志愿表" align="center" width="200px">
            <template slot-scope="scope">
              <el-button type="text" @click="goSP(scope.row.phone)"
                >点击查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="fy">
          <!-- 分页 -->
          <el-pagination
            layout=" sizes,prev, pager, next, jumper"
            :page-size="10"
            :page-sizes="[5]"
            :total="total"
            @current-change="hCurrentChange"
            @size-change="hSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { specialistGroupOrderList } from "@/api/News";
export default {
  name: "VoluntaryReportingMyOrder",

  data() {
    return {
      total: 0,
      name: "全部",
      obj: {
        Status: -1,
        CustomerId: "",
        PageIndex: 1,
        PageSize: 5,
      },
      list: [],
      userInfo: "",
    };
  },

  mounted() {
    this.userInfo = this.$store.state.user.studentInfo || "";
    this.getList();
  },

  methods: {
    goSP(phone) {
        this.$router.push({ path: "/Home/specialist", query: { phone } });
    },
    // 会自动接收当前点击的页码
    hCurrentChange(curPage) {
      // 1. 更新页码
      this.obj.PageIndex = curPage;
      // 2. 重发请求
      this.getList();
    },
    // 每页几条
    hSizeChange(curSize) {
      // 1. 更新每页的条数
      this.obj.PageSize = curSize;

      // 2. 重发请求
      this.getList();
    },
    // 获取个人信息
    async getList() {
      const res = await specialistGroupOrderList({
        catId: this.userInfo.cartId,
      });
      this.list = res.data.result;
    },
  },
};
</script>

<style lang="less" scoped>
.fy {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* 设置背景颜色 */
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
}

.boxs {
  background: #fff;
  position: relative;
  height: 100%;
}

.titles {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 73px;
}

.content {
}
</style>
